import { defineStore } from 'pinia'

export const useStoreSecao4 = defineStore('storeSecao4', {
  state: () => ({
    html: {
      hidden: false,
      codigoHtml: `
<section class="contadorRedireciona">
  <h3>10</h3>
  <p>Segundos</p>
</section>`,
      codigoCss: `
section.contadorRedireciona {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 100px;
  background: linear-gradient(20deg, #ffffff, #DDE3F1);
  background-color: #ffffff;
}

section.contadorRedireciona h3 {
  font-family: 'Figtree', sans-serif;
  font-weight: 800;
  font-size: 150px;
  line-height: 1;
  color: transparent;
  background-image: linear-gradient(90deg, #0000ff, #7300FF, #000000, #0000ff);
  background-size: 300%;
  background-clip: text;
  -webkit-background-clip: text;
  animation: animarDegrade 5s linear infinite;
}

section.contadorRedireciona p {
  font-family: 'Figtree', sans-serif;
  font-size: 30px;
  color: #000000;
}

@keyframes animarDegrade {
  0% {
    background-position: 0%;
  }
  50% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}`,
      codigoJs: `
// Seleciona o elemento <h3>
const elementoH3Contador = document.querySelector(".contadorRedireciona h3")

// Obtém o valor inicial, mesmo texto do H3 (10)
let contador = parseInt(elementoH3Contador.textContent)

const contagemRegressiva = setInterval(() => {
  if (contador > 1) {
    // Decrementa o número
    contador--
    // Atualiza o texto no <h3>
    elementoH3Contador.textContent = contador
  } else {
    // Para o intervalo quando chegar em 1
    clearInterval(contagemRegressiva)
    // Redireciona para a URL desejada
    window.location.href = "https://unicpages.com"
  }
}, 1000)
`
    }
  })
})
