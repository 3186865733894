<template>
  <Modal nome="sugerirIcone">
    <h3>Sentiu falta de algum ícone?</h3>
    <p>Sugira abaixo para o time Unic sempre ir adicionando!</p>
    <input type="text" spellcheck="false" autocomplete="off" placeholder="Ex: Ícone de Avião" v-model="state.sugestao" />

    <BotaoSalvar nome="Enviar Sugestão" @click="enviar" />
  </Modal>
</template>

<script setup>
import { reactive, onMounted, inject } from 'vue'
import axios from 'axios'
import { useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'

const emitter = inject('emitter')
const storeModal = useStoreModal()

const state = reactive({
  sugestao: '',
  loading: false
})

const webhookUrl = 'https://discord.com/api/webhooks/1315774670041255996/MMkE0bSAp0c4K22qs-TjO_QG-rfHzyXmab4AFE4DOnY8NtK1ihjpgBLiKPfAxGVNEPc9'

async function enviar() {
  if (!state.sugestao.trim()) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'A sugestão não pode estar vazia!' })
    return
  }

  state.loading = true

  try {
    const payload = {
      embeds: [
        {
          title: 'Nova Sugestão de Ícone',
          description: state.sugestao,
          color: 3447003 // Cor azul em decimal
        }
      ]
    }

    await axios.post(webhookUrl, payload)

    emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Sugestão enviada com sucesso!' })
    storeModal.fecharModal('sugerirIcone')
    limparCampos()
  } catch (error) {
    console.error('Erro ao enviar a sugestão:', error)
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao enviar a sugestão.' })
  } finally {
    state.loading = false
  }
}

function limparCampos() {
  state.sugestao = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}
p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 10px 0 15px 0;
}
button {
  margin: 20px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .titulo {
    padding: 20px;
  }

  .botao {
    padding: 20px;
  }

  .campos {
    padding: 20px 20px 0 20px;
  }
}
</style>
