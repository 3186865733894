<template>
  <div id="reativar">
    <SectionTopo />
    <SectionPlanos />
    <SectionContato />
    <SectionComparar />
    <SectionDuvida />
    <Footer />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'
import SectionTopo from '@components/app/reativar/partials/SectionTopo.vue'
import SectionPlanos from '@components/app/reativar/partials/SectionPlanos.vue'
import SectionComparar from '@components/app/planos/partials/SectionComparar.vue'
import Footer from '@components/global/footer/Footer.vue'
import SectionContato from '@components/app/planos/partials/SectionContato.vue'
import SectionDuvida from '@components/app/planos/partials/SectionDuvida.vue'

const storePerfil = useStorePerfil()
const router = useRouter()

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

async function verificarAssinatura() {
  await storePerfil.receberPerfilUsuario()

  if (storePerfil.usuario?.assinatura?.ativo === true) {
    router.push('/sites')
  }
}

onMounted(() => {
  verificarAssinatura()
  carregarPerfil()
})
</script>

<style scoped></style>
