import { defineStore } from 'pinia'

export const useStoreSecao5 = defineStore('storeSecao5', {
  state: () => ({
    html: {
      hidden: false,
      codigoHtml: '<section class="contadorDataFinal">\n  <h2>Tempo Restante para Acabar</h2>\n  <div class="contador">\n    <div class="tempo">\n      <span id="dias">00</span>\n      <p>Dias</p>\n    </div>\n    <div class="tempo">\n      <span id="horas">00</span>\n      <p>Horas</p>\n    </div>\n    <div class="tempo">\n      <span id="minutos">00</span>\n      <p>Minutos</p>\n    </div>\n    <div class="tempo">\n      <span id="segundos">00</span>\n      <p>Segundos</p>\n    </div>\n  </div>\n</section>',
      codigoCss: "section.contadorDataFinal {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: red;\n  background: linear-gradient(90deg, #ff0000, #FF3B67);\n  width: 100%;\n  padding: 80px;\n}\n\n.contadorDataFinal h2 {\n  font-family: 'Figtree';\n  font-weight: 500;\n  font-size: 25px;\n  margin: 0 25px 0 0;\n  color: #ffffff;\n}\n\n.contadorDataFinal .contador {\n  display: flex;\n  justify-content: center;\n}\n\n.contadorDataFinal .tempo {\n  background-color: #ffffff60;\n  padding: 15px;\n  text-align: center;\n  color: #FFFFFF;\n  border-radius: 10px;\n  width: 140px;\n  margin: 0 0 0 10px;\n}\n\n.contadorDataFinal .tempo span {\n  font-family: 'Figtree';\n  font-weight: 700;\n  font-size: 48px;\n}\n\n.contadorDataFinal .tempo p {\n  font-family: 'Figtree';\n  font-weight: 400;\n  font-size: 14px;\n}",
      codigoJs: '// Função para formatar o número com dois dígitos (ex.: 01, 09, 10)\nfunction formatarNumero(numero) {\n  return numero < 10 ? `0${numero}` : numero;\n}\n\n// Configuração da data final - Altere esta linha para definir a data final\nconst dataFinal = "31/12/2024 23:59"; // Formato DD/MM/AAAA HH:MM\nconst [dia, mes, ano, hora, minuto] = dataFinal.match(/\\d+/g).map(Number); // Extrai os números\nconst dataFinalFormatada = new Date(ano, mes - 1, dia, hora, minuto); // Cria um objeto de data\n\n// Função que calcula e atualiza o contador\nfunction atualizarContador() {\n  const agora = new Date(); // Data e hora atuais\n  const diferenca = dataFinalFormatada - agora; // Diferença em milissegundos\n\n  // Se a data final já passou, mostrar "00" em todos os campos\n  if (diferenca <= 0) {\n    document.getElementById("dias").textContent = "00";\n    document.getElementById("horas").textContent = "00";\n    document.getElementById("minutos").textContent = "00";\n    document.getElementById("segundos").textContent = "00";\n    clearInterval(intervalo); // Para o contador\n    return;\n  }\n\n  // Cálculos para dias, horas, minutos e segundos restantes\n  const dias = Math.floor(diferenca / (1000 * 60 * 60 * 24));\n  const horas = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));\n  const minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));\n  const segundos = Math.floor((diferenca % (1000 * 60)) / 1000);\n\n  // Atualizar o HTML com os valores\n  document.getElementById("dias").textContent = formatarNumero(dias);\n  document.getElementById("horas").textContent = formatarNumero(horas);\n  document.getElementById("minutos").textContent = formatarNumero(minutos);\n  document.getElementById("segundos").textContent = formatarNumero(segundos);\n}\n\n// Iniciar o contador\nconst intervalo = setInterval(atualizarContador, 1000);\natualizarContador(); // Chama imediatamente para evitar atraso inicial'
    }
  })
})
