import { defineStore } from 'pinia'

export const useStoreSecao2 = defineStore('storeSecao2', {
  state: () => ({
    html: {
      hidden: false,
      codigo: '\n<section class="slide">\n  <img src="https://arquivos.levelmember.com/imagens/web/icone-parceiros.svg"/>\n</section>\n<style>\n  section {\n    display: flex;\n    align-items: flex-start;\n    justify-content: flex-start;\n    width: 100%;\n    background: #FFFFFF;\n    border-top: 1px solid #C4C7DC;\n    border-bottom: 1px solid #C4C7DC;\n    padding: 30px 0;\n    overflow: hidden;\n  }\n  section img {\n    width: 3000px;\n    min-width: 3000px;\n    animation: movendoImg 300s linear infinite;\n  }\n  @keyframes movendoImg {\n    0% {\n      transform: translate(0px, 0)\n    }\n    100% {\n      transform: translate(-2000px, 0)\n    }\n  }\n</style>'
    }
  })
})
