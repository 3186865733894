<template></template>

<script setup>
import { watch, ref } from 'vue'
import { useStorePages, useStoreAjustes, useStoreCriar } from '@stores'

const storePages = useStorePages()
const storeCriar = useStoreCriar()
const storeAjustes = useStoreAjustes()

const processandoDesktop = ref(false)
const processandoMobile = ref(false)

async function receberRascunho() {
  storeCriar.mostrarLoading = false

  const idPage = storePages.page?._id

  if (idPage) {
    await storePages.receberPorId(idPage)
    const { desktop, mobile } = storePages.page?.codigoFonte?.rascunho || {}
    if (desktop?.length) {
      carregarSecoes(desktop, true)
    }

    if (mobile?.length) {
      carregarSecoes(mobile, false)
    }
  }

  storeCriar.mostrarLoading = true
}

function carregarSecoes(secoes, desktopDetectado) {
  if (desktopDetectado) {
    processandoDesktop.value = true
  } else {
    processandoMobile.value = true
  }

  const trabalhador = new Worker(new URL('@/helpers/jsonWorker.js', import.meta.url))

  trabalhador.postMessage({ secoes })

  trabalhador.onmessage = function (event) {
    const { sucesso, secoesParseadas } = event.data
    // console.log(secoesParseadas)

    if (sucesso) {
      secoesParseadas.forEach((secao) => {
        const { id, modelo: nomeSecao, propriedades: storeComponente } = secao
        storeCriar.adicionarSecao(nomeSecao, id, desktopDetectado)
        storeAjustes.adicionarSecao(nomeSecao, id, storeComponente, desktopDetectado)
      })
    }

    if (desktopDetectado) {
      processandoDesktop.value = false
    } else {
      processandoMobile.value = false
    }

    trabalhador.terminate()
  }
}

watch(
  () => storePages.page?._id,
  (idPage) => {
    if (idPage) {
      receberRascunho()
    }
  }
)
</script>
