<template>
  <Modal nome="linkBioIa" class="modalChatComIa">
    <div class="conteudo">
      <!-- Pergunta -->
      <div class="pergunta mostrar" v-if="storePerfil.usuario?.assinatura?.tipo === 'free'">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="'Olá, eu sou Unika, consigo criar link de bio em segundos para você!'" :tempoTotal="1000" :ativo="etapas.perguntaNomeUm" :finalizado="() => (etapas.perguntaNomeDois = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaNomeDois }">
          <Digitando :texto="'Mas vi que está no plano Free, faça o upgrade para liberar todo meu potencial e ter acesso à 100% das funcionalidades aqui na UnicPages!!!'" :tempoTotal="1000" :ativo="etapas.perguntaNomeDois" :finalizado="() => (etapas.parou = true)" />
        </div>
      </div>
      <!-- Pergunta -->
      <div class="pergunta mostrar" v-else>
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="'Olá, eu sou Unika, vou te ajudar na criação do seu link de BIO 😉'" :tempoTotal="1000" :ativo="etapas.perguntaNomeUm" :finalizado="() => (etapas.perguntaNomeDois = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaNomeDois }">
          <Digitando :texto="'Primeiro, qual nome gostaria de colocar no seu link?'" :tempoTotal="1000" :ativo="etapas.perguntaNomeDois" :finalizado="() => (etapas.respostaNome = true)" />
        </div>
      </div>
      <!-- Resposta -->
      <div class="resposta" v-if="etapas.respostaNome">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
        <input v-model="state.nomeDoSite" type="text" autocomplete="off" spellcheck="false" placeholder="Ex: Eu sou Unic" />
        <button @click="etapas.perguntaUrl = !etapas.perguntaUrl" v-if="state.nomeDoSite && !etapas.perguntaUrl">
          <Svgs nome="check" />
          <p>Confirmar</p>
        </button>
      </div>
      <!-- Pergunta -->
      <div class="pergunta" :class="{ mostrar: etapas.perguntaUrl }">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="`${state.nomeDoSite} é um bom nome! E como deseja que seja a URL?`" :tempoTotal="1000" :ativo="etapas.perguntaUrl" :finalizado="() => (etapas.perguntaUrlDois = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaUrlDois }">
          <Digitando :texto="`Ah, você poderá colocar seu próprio domínio depois!`" :tempoTotal="1000" :ativo="etapas.perguntaUrlDois" :finalizado="() => (etapas.respostaUrl = true)" />
        </div>
      </div>
      <!-- Resposta -->
      <div class="resposta" v-if="etapas.respostaUrl">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
        <div class="item">
          <div class="inputUrl">
            <p>unic.page/</p>
            <input v-model="state.hotlinkSite" @input="validarHotlink()" autocomplete="off" spellcheck="false" type="text" placeholder="meu-link-bio" />
          </div>
        </div>
        <button @click="etapas.perguntaInstagram = !etapas.perguntaInstagram" v-if="state.hotlinkSite && !etapas.perguntaInstagram">
          <Svgs nome="check" />
          <p>Confirmar</p>
        </button>
      </div>
      <!-- Pergunta -->
      <div class="pergunta" :class="{ mostrar: etapas.perguntaInstagram }">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="`Perfeito, seu link será unic.page/${state.hotlinkSite} 🏆`" :tempoTotal="1000" :ativo="etapas.perguntaInstagram" :finalizado="() => (etapas.perguntaInstagramDois = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaInstagramDois }">
          <Digitando :texto="`Qual @ do seu instagram? Ele será colocado no link de bio!`" :tempoTotal="1000" :ativo="etapas.perguntaInstagramDois" :finalizado="() => (etapas.respostaInstagram = true)" />
        </div>
      </div>
      <!-- Resposta -->
      <div class="resposta" v-if="etapas.respostaInstagram">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
        <input v-model="state.instagram" type="text" autocomplete="off" spellcheck="false" placeholder="@seuinstagram" />
        <button @click="etapas.perguntaWhatsapp = !etapas.perguntaWhatsapp" v-if="state.instagram && !etapas.perguntaWhatsapp">
          <Svgs nome="check" />
          <p>Confirmar</p>
        </button>
      </div>
      <!-- Pergunta -->
      <div class="pergunta" :class="{ mostrar: etapas.perguntaWhatsapp }">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="`Certo, e seu Whatsapp para entrarem em contato?`" :tempoTotal="1000" :ativo="etapas.perguntaWhatsapp" :finalizado="() => (etapas.respostaWhatsapp = true)" />
        </div>
      </div>
      <!-- Resposta -->
      <div class="resposta" v-if="etapas.respostaWhatsapp">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
        <input v-model="state.whatsapp" type="tel" autocomplete="off" spellcheck="false" placeholder="(00) 00000-0000" v-mask="['(##) ####-####', '(##) #####-####']" />
        <button @click="etapas.perguntaTiktok = !etapas.perguntaTiktok" v-if="state.whatsapp && !etapas.perguntaTiktok">
          <Svgs nome="check" />
          <p>Confirmar</p>
        </button>
      </div>
      <!-- Pergunta -->
      <div class="pergunta" :class="{ mostrar: etapas.perguntaTiktok }">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="`Agora digite seu tiktok abaixo:`" :tempoTotal="1000" :ativo="etapas.perguntaTiktok" :finalizado="() => (etapas.respostaTiktok = true)" />
        </div>
      </div>
      <!-- Resposta -->
      <div class="resposta" v-if="etapas.respostaTiktok">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
        <input v-model="state.tiktok" type="text" autocomplete="off" spellcheck="false" placeholder="@seutiktok" />
        <button @click="etapas.perguntaDescricao = !etapas.perguntaDescricao" v-if="state.tiktok && !etapas.perguntaDescricao">
          <Svgs nome="check" />
          <p>Confirmar</p>
        </button>
      </div>
      <!-- Pergunta -->
      <div class="pergunta" :class="{ mostrar: etapas.perguntaDescricao }">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="`Muito bom ${storePerfil.usuario?.nome} 😁 Agora vem a parte mais importante!`" :tempoTotal="1000" :ativo="etapas.perguntaDescricao" :finalizado="() => (etapas.perguntaDescricaoDois = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaDescricaoDois }">
          <Digitando :texto="`Preciso que você descreva o seu produto, serviço ou trabalho! Com isso vou conseguir criar bons textos em seu Link de Bio Unic!`" :tempoTotal="1000" :ativo="etapas.perguntaDescricaoDois" :finalizado="() => (etapas.perguntaDescricaoTres = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaDescricaoTres }">
          <Digitando :texto="`Quanto mais detalhes me enviar, melhor vou deixar seu link de bio!`" :tempoTotal="1000" :ativo="etapas.perguntaDescricaoTres" :finalizado="() => (etapas.respostaDescricao = true)" />
        </div>
      </div>
      <!-- Resposta -->
      <div class="resposta" v-if="etapas.respostaDescricao">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
        <textarea v-model="state.descricaoUsuario" autocomplete="off" spellcheck="false" placeholder="Ex: Sou desenvolvedor de sites especialista em landing pages" v-redimensionar-textarea></textarea>
        <button @click="etapas.perguntaFoto = !etapas.perguntaFoto" v-if="state.descricaoUsuario && !etapas.perguntaFoto">
          <Svgs nome="check" />
          <p>Confirmar</p>
        </button>
      </div>
      <!-- Pergunta -->
      <div class="pergunta" :class="{ mostrar: etapas.perguntaFoto }">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="`Era o que eu precisava, muito obrigada!`" :tempoTotal="1000" :ativo="etapas.perguntaFoto" :finalizado="() => (etapas.perguntaFotoDois = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaFotoDois }">
          <Digitando :texto="`Agora preciso de uma foto sua para colocar no link de bio!`" :tempoTotal="1000" :ativo="etapas.perguntaFotoDois" :finalizado="() => (etapas.perguntaFotoTres = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaFotoTres }">
          <Digitando :texto="`Fotos quadradas ficam melhores, só uma dica 😊`" :tempoTotal="1000" :ativo="etapas.perguntaFotoTres" :finalizado="() => (etapas.respostaFoto = true)" />
        </div>
      </div>
      <!-- Resposta -->
      <div class="resposta" v-if="etapas.respostaFoto">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
        <div class="inputImagem" :class="{ carregar: state.loading }" @click="abrirInputFile">
          <span></span>
          <div class="previa" :style="`background-image: url('${state.fotoCapa || ''}')`">
            <Svgs v-if="!state.fotoCapa" nome="arquivo" />
          </div>
          <div class="texto">
            <h3>{{ state.nomeArquivo || 'Subir Foto' }}</h3>
            <p>{{ state.tamanhoArquivo || 'Tamanho recomendado: 500x500' }}</p>
          </div>
          <input type="file" accept=".jpg,.jpeg,.png" hidden @change="selecionarImagem" ref="inputFile" />
        </div>
        <button @click="etapas.perguntaCor = !etapas.perguntaCor" v-if="state.fotoCapa && !etapas.perguntaCor">
          <Svgs nome="check" />
          <p>Confirmar</p>
        </button>
      </div>
      <!-- Pergunta -->
      <div class="pergunta" :class="{ mostrar: etapas.perguntaCor }">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="`Uma bela foto viu ${storePerfil.usuario?.nome}!`" :tempoTotal="1000" :ativo="etapas.perguntaCor" :finalizado="() => (etapas.perguntaCorDois = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaCorDois }">
          <Digitando :texto="`Para finalizar, preciso saber qual cor deseja que eu destaque no Link de Bio.`" :tempoTotal="1000" :ativo="etapas.perguntaCorDois" :finalizado="() => (etapas.perguntaCorTres = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaCorTres }">
          <Digitando :texto="`Ah, cores muito claras podem ficar ruins nos botões para ler.`" :tempoTotal="1000" :ativo="etapas.perguntaCorTres" :finalizado="() => (etapas.respostaCor = true)" />
        </div>
      </div>
      <!-- Resposta -->
      <div class="resposta" v-if="etapas.respostaCor">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
        <div class="item">
          <ColorPicker class="mobile" :valor="state.corPrincipal" :onAtualizarCor="atualizarCorSelecionada" />
        </div>
        <button @click="etapas.perguntaCriar = !etapas.perguntaCriar" v-if="!etapas.perguntaCriar">
          <Svgs nome="check" />
          <p>Confirmar</p>
        </button>
      </div>
      <!-- Pergunta -->
      <div class="pergunta" :class="{ mostrar: etapas.perguntaCriar }">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="`Era tudo que eu precisava!`" :tempoTotal="1000" :ativo="etapas.perguntaCriar" :finalizado="() => (etapas.perguntaCriarDois = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaCriarDois }">
          <Digitando :texto="`Revise o que respondeu para ver se tudo está correto!`" :tempoTotal="1000" :ativo="etapas.perguntaCriarDois" :finalizado="() => (etapas.perguntaCriarTres = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.perguntaCriarTres }">
          <Digitando :texto="`Se tudo estiver OK, só clicar no botão que irei criar seu Link de Bio 🚀`" :tempoTotal="1000" :ativo="etapas.perguntaCriarTres" :finalizado="() => (etapas.respostaCriar = true)" />
        </div>
      </div>
      <!-- Resposta -->
      <div class="resposta" v-if="etapas.respostaCriar">
        <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil}')`"></div>
        <button class="destacado" @click="etapas.criando = !etapas.criando" v-if="!etapas.criando">
          <Svgs nome="ia-flat" />
          <p>Pode criar meu link de BIO</p>
        </button>
      </div>
      <!-- Pergunta -->
      <div class="pergunta" :class="{ mostrar: etapas.criando }">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="`Estou criando seu link de bio agora... Aguarde alguns segundos`" :tempoTotal="1000" :ativo="etapas.criando" :finalizado="() => criarSite()" />
          <span></span>
        </div>
      </div>
      <!-- Pergunta -->
      <div class="pergunta" :class="{ mostrar: etapas.criado }">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="mensagem mostrar">
          <Digitando :texto="`Perfeição total 🟢 Seu link de bio foi criado com sucesso ${storePerfil.usuario?.nome}!`" :tempoTotal="1000" :ativo="etapas.criado" :finalizado="() => (etapas.redirecionar = true)" />
        </div>
        <div class="mensagem" :class="{ mostrar: etapas.redirecionar }">
          <Digitando :texto="`Estou te redirecionando...`" :tempoTotal="1500" :ativo="etapas.redirecionar" :finalizado="() => irParaSite()" />
        </div>
      </div>
    </div>
  </Modal>
</template>
<script setup>
import pako from 'pako'
import { inject, ref, reactive, onMounted } from 'vue'
import { useStorePages, useStoreGpt, useStorePerfil } from '@stores'
import { useStoreTemplateProntoLinkBioDesktop } from '@/components/app/templates/prontos/linkBioDesktop.js'
import { useStoreTemplateProntoLinkBioMobile } from '@/components/app/templates/prontos/linkBioMobile.js'
import Modal from '@components/global/modal/Modal.vue'
import ApiUpload from '@/api/upload/api-upload.js'
import Svgs from '@svgs'
import Digitando from '@/components/global/elementos/Digitando.vue'
import ColorPicker from '@/components/global/elementos/ColorPicker.vue'

const storeTemplateProntoLinkBioDesktop = useStoreTemplateProntoLinkBioDesktop()
const storeTemplateProntoLinkBioMobile = useStoreTemplateProntoLinkBioMobile()

const emitter = inject('emitter')
const storePerfil = useStorePerfil()
const storePages = useStorePages()
const storeGpt = useStoreGpt()
const inputFile = ref(null)

function estadoInicialEtapas() {
  return {
    perguntaNomeUm: false,
    perguntaNomeDois: false,
    respostaNome: false,
    perguntaUrl: false,
    perguntaUrlDois: false,
    respostaUrl: false,
    perguntaInstagram: false,
    perguntaInstagramDois: false,
    respostaInstagram: false,
    perguntaWhatsapp: false,
    respostaWhatsapp: false,
    perguntaTiktok: false,
    perguntaTiktokDois: false,
    respostaTiktok: false,
    perguntaDescricao: false,
    perguntaDescricaoDois: false,
    perguntaDescricaoTres: false,
    respostaDescricao: false,
    perguntaFoto: false,
    perguntaFotoDois: false,
    perguntaFotoTres: false,
    respostaFoto: false,
    perguntaCor: false,
    perguntaCorDois: false,
    perguntaCorTres: false,
    respostaCor: false,
    perguntaCriar: false,
    perguntaCriarDois: false,
    perguntaCriarTres: false,
    respostaCriar: false,
    criando: false,
    criado: false,
    redirecionar: false
  }
}

function estadoInicialState() {
  return {
    ativo: false,
    nomeDoSite: '',
    descricaoDoSite: 'Link de BIO personalizado',
    hotlinkSite: '',
    fotoCapa: '',
    corPrincipal: '#FFFFFF',
    nomeArquivo: '',
    tamanhoArquivo: '',
    instagram: '',
    tiktok: '',
    whatsapp: '',
    descricaoBio: '',
    descricaoUsuario: '',
    opcaoUmTexto: '',
    opcaoUmBotao: '',
    opcaoDoisTexto: '',
    opcaoDoisBotao: '',
    opcaoTresTexto: '',
    opcaoTresBotao: '',
    opcaoQuatroTexto: '',
    opcaoQuatroBotao: '',
    tituloCatalogo: '',
    descricaoCatalogo: '',
    catalogoUmTitulo: '',
    catalogoUmDescricao: '',
    catalogoUmBotao: '',
    catalogoDoisTitulo: '',
    catalogoDoisDescricao: '',
    catalogoDoisBotao: '',
    catalogoTresTitulo: '',
    catalogoTresDescricao: '',
    catalogoTresBotao: '',
    loading: false,
    botaoSalvar: false,
    secoesDesktop: [],
    secoesMobile: []
  }
}

const etapas = reactive(estadoInicialEtapas())
const state = reactive(estadoInicialState())

function limparCampos() {
  Object.assign(etapas, estadoInicialEtapas())
  Object.assign(state, estadoInicialState())
}

function iniciarMensagem() {
  etapas.perguntaNomeUm = true
}

function atualizarCorSelecionada(novaCor) {
  state.corPrincipal = novaCor
}

function abrirInputFile() {
  inputFile.value.click()
}

function selecionarImagem(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.loading = true

  const payload = {
    arquivo: arquivoSelecionado,
    maxWidth: 800
  }

  ApiUpload.uploadImagem(payload)
    .then((resp) => {
      state.loading = false
      state.fotoCapa = resp?.url
      state.nomeArquivo = resp?.nome
      state.tamanhoArquivo = resp?.peso
    })
    .catch(() => {
      state.loading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir imagem de capa' })
    })
}

function validarHotlink() {
  let hotlink = state.hotlinkSite

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlinkSite = hotlink
}

async function criarDescricaoComIA() {
  try {
    const mensagem = `
Meu nome é ${state.nomeDoSite},
informações importantes: ${state.descricaoUsuario},
tudo para você usar no que vou pedir agora.

Não dê explicações nem nada, apenas crie o que irei pedir:

- Crie uma lista enumerada (1. 2. 3. e assim vai conforme eu for pedindo)
- No item 1, quero uma descrição com mais ou menos 35 palavras que faça sentido com o que te passei de informações importantes e meu nome. Essa descrição não pode ser tão curta nem tão longa mas deve ser bem chamativa de acordo com as informações que mandei.
- Nos itens 2, 3, 4, e 5, crie uma curta descrição de umas 6 palavras pensando nas informações importantes que passei, pense que será para um link de bio (algo como Fazer orçamento no whatsapp, veja meus produtos, porém deve fazer sentido com o que passei de informações importantes).
- Nos itens 6, 7, 8, e 9, crie botões para os itens acima (no máximo 2 palavras como Saiba Mais, Enviar Mensagem, ou coisas do tipo ) lembrando que 6 é o botão do item 2, 7 é o botão do item 3, 8 é o botão do item 4 e 9 é o botão do item 5.

No item 10, escreva o título para uma seção de catálogo. Deve fazer sentido com as informações importantes. Esse título deve ter em média 4, 5 palavras.
No item 11, uma descrição breve para acompanhar o título do item 10.

Para os itens 12 a 20 tem informações importantes:
- 12, 15 e 18 serão os títulos de 3 quadros, com até 5 palavras cada.
- 13, 16 e 19 serão descrições de 15 palavras.
- 14, 17 e 20 serão os botões dos quadros, com até 2 palavras cada.

Esses quadros devem ser algo que envolve também o que pedi no título, então se eu te passei de informações importantes veja o que faz mais sentido ter no link de bio e monte esses quadros.

Novamente, envie apenas a lista enumerada e correta, sem explicações e do jeito que pedi acima.
`

    const respostaIA = await storeGpt.chat({ mensagem })

    const itens = extrairItensLista(respostaIA)

    // Descrição Bio
    state.descricaoBio = itens[0] || 'Essa é a descrição da sua Bio'
    // Opções textos
    state.opcaoUmTexto = itens[1] || 'Essa é a primeira opção'
    state.opcaoDoisTexto = itens[2] || 'Essa é a segunda opção'
    state.opcaoTresTexto = itens[3] || 'Essa é a terceira opção'
    state.opcaoQuatroTexto = itens[4] || 'Essa é a quarta opção'
    // Opção botões
    state.opcaoUmBotao = itens[5] || 'BOTÃO UM'
    state.opcaoDoisBotao = itens[6] || 'BOTÃO DOIS'
    state.opcaoTresBotao = itens[7] || 'BOTÃO TRES'
    state.opcaoQuatroBotao = itens[8] || 'BOTÃO QUATRO'
    // Título e descrição do catálogo
    state.tituloCatalogo = itens[9] || 'Título do catálogo'
    state.descricaoCatalogo = itens[10] || 'Essa é a descrição do seu catálogo'
    // Quadro 1 do catálogo
    state.catalogoUmTitulo = itens[11] || 'Título do quadro um'
    state.catalogoUmDescricao = itens[12] || 'Essa é a sua descrição de bio para o quadro número um'
    state.catalogoUmBotao = itens[13] || 'BOTÃO UM'
    // Quadro 2 do catálogo
    state.catalogoDoisTitulo = itens[14] || 'Título do quadro dois'
    state.catalogoDoisDescricao = itens[15] || 'Essa é a sua descrição de bio para o quadro número dois'
    state.catalogoDoisBotao = itens[16] || 'BOTÃO DOIS'
    // Quadro 3 do catálogo
    state.catalogoTresTitulo = itens[17] || 'Título do quadro três'
    state.catalogoTresDescricao = itens[18] || 'Essa é a sua descrição de bio para o quadro número três'
    state.catalogoTresBotao = itens[19] || 'BOTÃO TRÊS'
  } catch (error) {
    console.error('Erro ao gerar a lista com IA:', error)
  }
}

function extrairItensLista(resposta) {
  const regex = /^\d+\.\s*(.*)$/gm
  const itens = []
  let match

  while ((match = regex.exec(resposta)) !== null) {
    itens.push(match[1].trim())
  }

  return itens
}

function substituirValorAninhado(obj, valorAlvo, novoValor) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      substituirValorAninhado(obj[key], valorAlvo, novoValor)
    } else if (obj[key] === valorAlvo) {
      obj[key] = novoValor
    }
  }
}

function preencherSecoes() {
  iniciarMensagem()
  const preencher = (store) => {
    return Object.keys(store.$state).map((key) => {
      const secao = store.$state[key]
      return {
        tipo: secao.tipo,
        id: gerarIdAleatorio(),
        modelo: secao.modelo,
        propriedades: { ...secao.propriedades }
      }
    })
  }

  state.secoesDesktop = preencher(storeTemplateProntoLinkBioDesktop)
  state.secoesMobile = preencher(storeTemplateProntoLinkBioMobile)
}

function executarSubstituicoes() {
  const corSemHash = state.corPrincipal.startsWith('#') ? state.corPrincipal.slice(1) : state.corPrincipal
  const corEscurecida = escurecerCor(state.corPrincipal, 50)
  const whatsappFormatado = `https://wa.me/55${state.whatsapp.replace(/\D/g, '')}`
  const instagramFormatado = `https://instagram.com/${state.instagram.replace(/^@/, '')}`
  const tiktokFormatado = `https://tiktok.com/${state.tiktok.replace(/^@/, '')}`

  const substituicoes = {
    CORDESTAQUE: corSemHash,
    CORESCURA: corEscurecida,
    NOMEAQUI: state.nomeDoSite,
    FOTOAQUI: state.fotoCapa,
    WHATSAPP: whatsappFormatado,
    INSTAGRAM: instagramFormatado,
    TIKTOK: tiktokFormatado,
    DESCRICAO: state.descricaoBio,
    OPCAOUMTEXTO: state.opcaoUmTexto,
    OPCAOUMBOTAO: state.opcaoUmBotao,
    OPCAODOISTEXTO: state.opcaoDoisTexto,
    OPCAODOISBOTAO: state.opcaoDoisBotao,
    OPCAOTRESTEXTO: state.opcaoTresTexto,
    OPCAOTRESBOTAO: state.opcaoTresBotao,
    OPCAOQUATROTEXTO: state.opcaoQuatroTexto,
    OPCAOQUATROBOTAO: state.opcaoQuatroBotao,
    TITULOCATALOGO: state.tituloCatalogo,
    DESCRICAOCATALOGO: state.descricaoCatalogo,
    CATALOGOUMTITULO: state.catalogoUmTitulo,
    CATALOGOUMDESCRICAO: state.catalogoUmDescricao,
    CATALOGOUMBOTAO: state.catalogoUmBotao,
    CATALOGODOISTITULO: state.catalogoDoisTitulo,
    CATALOGODOISDESCRICAO: state.catalogoDoisDescricao,
    CATALOGODOISBOTAO: state.catalogoDoisBotao,
    CATALOGOTRESTITULO: state.catalogoTresTitulo,
    CATALOGOTRESDESCRICAO: state.catalogoTresDescricao,
    CATALOGOTRESBOTAO: state.catalogoTresBotao
  }

  const secoes = [...state.secoesDesktop, ...state.secoesMobile]

  secoes.forEach((secao) => {
    for (const chave in substituicoes) {
      substituirValorAninhado(secao.propriedades, chave, substituicoes[chave])
    }
  })
}

function escurecerCor(hex, percentual) {
  const corSemHash = hex.startsWith('#') ? hex.slice(1) : hex

  const r = parseInt(corSemHash.substring(0, 2), 16)
  const g = parseInt(corSemHash.substring(2, 4), 16)
  const b = parseInt(corSemHash.substring(4, 6), 16)

  const novoR = Math.max(0, Math.min(255, r - (r * percentual) / 100))
  const novoG = Math.max(0, Math.min(255, g - (g * percentual) / 100))
  const novoB = Math.max(0, Math.min(255, b - (b * percentual) / 100))

  const hexEscurecido = [novoR, novoG, novoB].map((c) => Math.round(c).toString(16).padStart(2, '0')).join('')

  return hexEscurecido
}

function gerarIdAleatorio() {
  return Math.random().toString(36).substr(2, 10)
}

async function criarSite() {
  if (!validarDados()) return
  state.botaoSalvar = true
  etapas.criando = true
  preencherSecoes()
  await criarDescricaoComIA()
  executarSubstituicoes()

  const payload = {
    nome: state.nomeDoSite,
    descricao: state.descricaoDoSite,
    cor: 'FFFFFF',
    imagemCapa: state.fotoCapa,
    hotlink: state.hotlinkSite,
    online: true,
    metatags: {
      titulo: state.nomeDoSite,
      descricao: state.descricaoDoSite,
      favicon: 'https://arquivos.unicpages.com/favicons/favicon.icohttps://arquivos.unicpages.com/favicons/favicon.png'
    },
    codigoFonte: {
      rascunho: {
        desktop: JSON.stringify([]),
        mobile: JSON.stringify([])
      }
    }
  }

  const adicionadoOk = await storePages.adicionar(payload)
  if (adicionadoOk !== true) {
    etapas.criando = false
    state.botaoSalvar = false
    etapas.respostaCriar = true
    return
  }

  const response = await storePages.receberPorHotlink(state.hotlinkSite)
  if (response) {
    atualizarRascunhoBackend(storePages.page?._id)
  }
}

async function atualizarRascunhoBackend(idPage) {
  const payload = {
    idPage: idPage,
    rascunhoComprimidoDesktop: compressPayload(state.secoesDesktop),
    rascunhoComprimidoMobile: compressPayload(state.secoesMobile)
  }

  try {
    await storePages.alterarRascunho(payload, true)
    storePages.publicar(idPage)
    etapas.criado = true
  } catch (error) {
    console.error('Erro ao criar:', error)
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao criar seu link de Bio' })
  }
}

function irParaSite() {
  window.location.assign(`/criar/${state.hotlinkSite}`)
}

const compressPayload = (content) => {
  const jsonString = JSON.stringify(content)
  const compressedString = String.fromCharCode(...pako.deflate(new TextEncoder().encode(jsonString)))
  return btoa(compressedString)
}

function validarDados() {
  let mensagem
  if (!state.nomeDoSite || !state.hotlinkSite || !state.fotoCapa || !state.descricaoUsuario || !state.whatsapp || !state.instagram || !state.tiktok) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

onMounted(() => {
  emitter.on('carregarCampos', preencherSecoes)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

label.um {
  margin: 0 0 10px 0;
}

button.salvar {
  margin: 20px 0 0 0;
}

.pergunta {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  padding: 0 0 0 35px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.pergunta.mostrar {
  display: flex;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  animation: mostrarPergunta 0.3s linear;
}

@keyframes mostrarPergunta {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate(-10px, 0);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}

.pergunta .foto {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 10px;
  overflow: hidden;
}

.pergunta .foto svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco-fixo);
  animation: zoom 2s ease-in-out infinite;
  z-index: 2;
}

.pergunta .foto .fundo {
  position: absolute;
  width: 100%;
  height: 100%;
  width: 40px;
  height: 40px;
  background: linear-gradient(20deg, var(--degrade));
  animation: girando 10s linear infinite;
  border-radius: 50%;
}

.pergunta .mensagem {
  display: none;
  align-items: center;
  padding: 10px;
  background-color: var(--cor-azul);
  border-radius: 0 10px 10px 10px;
  margin: 0 0 15px 0;
  position: relative;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s;
}

.pergunta .mensagem.mostrar {
  display: flex;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  animation: mostrarProximaPergunta 0.3s linear;
}

@keyframes mostrarProximaPergunta {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate(0, -10px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}

.pergunta .mensagem p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  line-height: 1.5;
}

.pergunta .mensagem span {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid var(--cor-azul);
  border-top: 3px solid transparent;
  box-shadow: 0 0 20px 0 var(--cor-azul);
  position: absolute;
  right: -25px;
  animation: girando 1s linear infinite;
}

.resposta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  position: relative;
  padding: 10px 35px 20px 0;
  opacity: 1;
  visibility: visible;
  animation: mostrarResposta 0.3s linear;
}

@keyframes mostrarResposta {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate(10px, 0);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}

.resposta .foto {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.resposta input {
  max-width: 250px;
}

.resposta button {
  display: flex;
  align-items: center;
  background-color: var(--cor-azul);
  padding: 10px 15px;
  border-radius: 50px;
  transition: all 0.3s;
  margin: 10px 0 0 0;
}

.resposta button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco-fixo);
  margin: 0 5px 0 0;
}

.resposta button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
}

.resposta button:hover {
  background-color: var(--cor-azul-escuro);
}

.resposta button.destacado:hover {
  filter: brightness(120%);
}

.resposta button.destacado {
  background: linear-gradient(40deg, var(--degrade));
  padding: 20px 30px;
}

.resposta button.destacado svg {
  width: 15px;
  min-width: 15px;
}

.resposta button.destacado p {
  font-size: var(--f2);
}

.resposta .item {
  max-width: 280px;
}

.resposta .inputImagem {
  max-width: 350px;
}

.resposta textarea {
  max-width: 300px;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
