<template>
  <Modal nome="compartilhar">
    <div class="conteudo" v-if="storePerfil.usuario?.assinatura?.tipo === 'free'">
      <!-- plano free -->
      <div class="free">
        <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-free.svg" />
        <h3>Compartilhar Edição</h3>
        <p>Libere para seu time, amigos ou sócios editarem o site através da conta deles. Em qualquer plano Unic você tem acesso a usuários ilimitados em todos os sites que deseja compartilhar, faça seu upgrade agora mesmo.</p>
        <button @click="router.push('/planos')">
          <Svgs nome="estrela" />
          Fazer Upgrade
        </button>
      </div>
    </div>
    <div class="conteudo" v-else>
      <!-- Adicionados -->
      <div class="adicionados">
        <div class="titulo">
          <h3>Site compartilhado</h3>
          <p>Abaixo estão os usuários Unic que podem editar seu site</p>
        </div>
        <div class="usuario" v-for="(usuario, index) in state.usuariosComAcesso" :key="index">
          <div class="foto" :style="`background-image: url('${usuario.imagemPerfil}')`"></div>
          <div class="nome">
            <h3>{{ usuario.nome }}</h3>
            <p>{{ usuario.email }}</p>
          </div>
          <button class="excluir" @click="removerUsuario(index)">
            <Svgs nome="excluir" />
          </button>
        </div>
        <BotaoSalvar @click="salvarDados()" nome="Salvar alterações" />
      </div>
      <!-- Adicionar -->
      <div class="adicionar">
        <!-- Etapa 1 -->
        <div class="etapa" v-if="state.etapaUm">
          <label>
            Adicionar um usuário
            <span>*</span>
          </label>
          <div class="input">
            <Svgs class="icone" nome="envelope" />
            <input type="text" autocomplete="off" spellcheck="false" placeholder="Buscar por e-mail..." v-model="state.email" @keyup.enter="buscarPorEmail()" />
            <button @click="buscarPorEmail()">Buscar</button>
          </div>
        </div>
        <!-- Etapa 2 -->
        <div class="etapa" v-if="state.etapaDois">
          <div class="usuario">
            <div class="foto" :style="`background-image: url('${storePerfil.usuarioPorEmail?.imagemPerfil}')`"></div>
            <div class="nome">
              <h3>{{ storePerfil.usuarioPorEmail?.nome }}</h3>
              <p>{{ storePerfil.usuarioPorEmail?.email }}</p>
            </div>
            <button class="confirmar" @click="adicionarUsuario()">Adicionar Usuário</button>
          </div>
          <button class="voltar" @click="buscarNovamente()">Buscar por outro usuário</button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import { reactive, onMounted, inject } from 'vue'
import { useStorePages, useStorePerfil } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import Svgs from '@svgs'

const storePages = useStorePages()
const storePerfil = useStorePerfil()
const emitter = inject('emitter')

const state = reactive({
  nomeDoSite: '',
  email: '',
  usuariosComAcesso: [],
  etapaUm: true,
  etapaDois: false,
  confirmar: false
})

function preencherDados() {
  state.usuariosComAcesso = storePages.page?.compartilhadoCom
}

function limparCampos() {
  state.nomeDoSite = ''
}

function buscarNovamente() {
  storePerfil.usuarioPorEmail = null
  state.etapaDois = false
  state.etapaUm = true
  state.email = ''
}

async function buscarPorEmail() {
  if (!validarDados()) return

  const adicionadoOk = await storePerfil.receberPorEmail(state.email)
  if (!storePerfil.usuarioPorEmail) {
    emitter.emit('mostrarAlerta', {
      tipo: 'negativo',
      mensagem: 'Nenhum usuário encontrado, tente novamente'
    })
    return
  }
  if (adicionadoOk !== true) return

  state.etapaUm = false
  state.etapaDois = true
}

function adicionarUsuario() {
  const usuario = {
    nome: storePerfil.usuarioPorEmail?.nome,
    email: storePerfil.usuarioPorEmail?.email,
    imagemPerfil: storePerfil.usuarioPorEmail?.imagemPerfil,
    _id: storePerfil.usuarioPorEmail?._id
  }
  state.usuariosComAcesso.push(usuario)
  buscarNovamente()
}

function removerUsuario(index) {
  state.usuariosComAcesso.splice(index, 1)
}

async function salvarDados() {
  const payload = {
    idPage: storePages.page?._id,
    compartilhadoCom: state.usuariosComAcesso.map((usuario) => usuario._id) // Apenas IDs
  }

  const edicaoOk = await storePages.alterarDados(payload)

  if (edicaoOk !== true) {
    emitter.emit('mostrarAlerta', {
      tipo: 'negativo',
      mensagem: 'Erro ao salvar os dados, tente novamente.'
    })
    return
  }

  emitter.emit('mostrarAlerta', {
    tipo: 'positivo',
    mensagem: 'Usuários salvos com sucesso!'
  })
}

function validarDados() {
  let mensagem
  if (!state.email) mensagem = 'Preencha o e-mail que deseja convidar'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

onMounted(() => {
  emitter.on('carregarCampos', preencherDados)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.free {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0;
}

.free img {
  width: 100%;
  max-width: 50px;
  margin: 0 0 20px 0;
}

.free h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  text-align: center;
}

.free p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  text-align: center;
  line-height: 1.5;
  width: 100%;
  max-width: 400px;
  margin: 5px 0 10px 0;
}

.free button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  text-align: center;
  background-color: var(--cor-laranja);
  padding: 15px 30px;
  border-radius: 10px;
  transition: all 0.3s;
}

.free button:hover {
  background-color: var(--cor-laranja-escuro);
}

.free button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.adicionados {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.adicionar {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0 0 0;
}

.adicionar .etapa {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0 0 10px 0;
}

.titulo h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 10px 0;
}

label {
  margin: 0 0 10px 0;
}

.usuario {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
  border: 2px solid var(--cor-cinza-3);
  margin: 0 0 10px 0;
}

.usuario .foto {
  width: 40px;
  height: 40px;
  background-color: var(--cor-cinza-4);
  border-radius: 50%;
  margin: 0 10px 0 0;
  background-size: cover;
  background-position: center;
}

.usuario .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.usuario .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
}

.usuario .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.usuario button.confirmar {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  padding: 10px 20px;
  border-radius: 10px;
  margin-left: auto;
  transition: all 0.3s;
}

.usuario button.confirmar:hover {
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
}

.usuario button.excluir {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--cor-vermelho);
  margin-left: auto;
  transition: all 0.3s;
}

.usuario button.excluir:hover {
  background-color: var(--cor-vermelho-escuro);
}

.usuario button.excluir svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
}

.adicionar .inputAtivacao {
  margin: 15px 0;
}

.adicionar .input {
  margin: 0 0 20px 0;
}

.adicionar .input button {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
  padding: 15px 20px;
  border-radius: 10px;
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.adicionar .input button:hover {
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
}

.adicionar button.voltar {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  background-color: transparent;
  text-decoration: underline;
  padding: 5px;
  margin: 15px 0 0 0;
  transition: all 0.3s;
}

.adicionar button.voltar:hover {
  color: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
