import token from './token'
import validacoes from './validacoes'
import texto from './helperTexto'
import detectarClickForaDoElemento from './detectarClickForaDoElemento'
import cursos from './cursos'
import dragScroll from './dragScroll'
import data from './helperData'
import detectarMobile from './detectarMobile'
import scrollTop from './scrollTop'
import transformarLinksVideos from './transformarLinksVideos'
import abrirElementoPosicaoMouse from './abrirElementoPosicaoMouse'
import slide from './slide'
import redimensionar from './redimensionar'
import bloqueioBody from './bloqueioBody'
import serviceWorker from './serviceWorker'
import redirectBrowser from './redirectBrowser'
import id from './id'

export const helperToken = token
export const helperValidacoes = validacoes
export const helperTexto = texto
export const helperCurso = cursos
export const helperDragScroll = dragScroll
export const helperData = data
export const helperMobile = detectarMobile
export const helperScrollTopo = scrollTop
export const helperLinkVideo = transformarLinksVideos
export const helperDetectarPosicaoMouse = abrirElementoPosicaoMouse
export const helperSlide = slide
export const helperRedimensionar = redimensionar
export const helperClicouFora = detectarClickForaDoElemento
export const helperBloqueioBody = bloqueioBody
export const helperServiceWorker = serviceWorker
export const helperRedirectBrowser = redirectBrowser
export const helperId = id
