<template>
  <div class="text" :class="{ maior: state.zoom }" v-if="!state.html">
    <h3>Monte sua seção com código com HTML, CSS e JS. O JavaScript funciona apenas no site em produção.</h3>
    <div class="abas">
      <button class="html" :class="{ ativo: state.selecionado === 'html' }" @click="state.selecionado = 'html'">
        <Svgs nome="html" />
        <p>HTML</p>
      </button>
      <button class="css" :class="{ ativo: state.selecionado === 'css' }" @click="state.selecionado = 'css'">
        <Svgs nome="css" />
        <p>CSS</p>
      </button>
      <button class="js" :class="{ ativo: state.selecionado === 'js' }" @click="state.selecionado = 'js'">
        <Svgs nome="js" />
        <p>JS</p>
      </button>
    </div>
    <MonacoEditor v-if="state.selecionado === 'html'" class="editor" v-model="state.codigoHtml" language="html" />
    <MonacoEditor v-if="state.selecionado === 'css'" class="editor" v-model="state.codigoCss" language="css" />
    <MonacoEditor v-if="state.selecionado === 'js'" class="editor" v-model="state.codigoJs" language="javascript" />
    <button class="zoom" @click="state.zoom = !state.zoom">
      <Svgs :nome="state.zoom ? 'x' : 'zoom-in'" />
      <p>{{ state.zoom ? '' : 'Aumentar' }}</p>
    </button>
  </div>
  <div class="text" :class="{ maior: state.zoom }" v-else>
    <h3>Monte sua seção com código. Use HTML e CSS. JavaScript não é permitido nesse campo.</h3>
    <MonacoEditor class="editor" v-model="state.html" language="html" />
    <button class="zoom" @click="state.zoom = !state.zoom">
      <Svgs nome="zoom-in" />
      <p>Aumentar</p>
    </button>
  </div>
</template>

<script setup>
import { reactive, watchEffect, watch } from 'vue'
import { useStoreAjustes } from '@stores'
import MonacoEditor from '@components/global/editor/Editor.vue'
import Svgs from '@svgs'

const storeAjustes = useStoreAjustes()

const props = defineProps(['selecao'])
const state = reactive({
  html: null,
  codigoHtml: null,
  codigoCss: null,
  codigoJs: null,
  selecionado: 'html',
  zoom: false,
  carregado: false
})

function editarText() {
  const elemento = elementoSelecionado()
  if (elemento) {
    if (elemento.codigo !== undefined) elemento.codigo = state.html
    if (elemento.codigoHtml !== undefined) elemento.codigoHtml = state.codigoHtml
    if (elemento.codigoCss !== undefined) elemento.codigoCss = state.codigoCss
    if (elemento.codigoJs !== undefined) elemento.codigoJs = state.codigoJs
  }
}

function carregarText() {
  const elemento = elementoSelecionado()
  if (elemento) {
    state.html = elemento.codigo !== undefined ? elemento.codigo : ''
    state.codigoHtml = elemento.codigoHtml !== undefined ? elemento.codigoHtml : ''
    state.codigoCss = elemento.codigoCss !== undefined ? elemento.codigoCss : ''
    state.codigoJs = elemento.codigoJs !== undefined ? elemento.codigoJs : ''
    state.carregado = true
  } else {
    state.html = ''
    state.codigoHtml = ''
    state.codigoCss = ''
    state.codigoJs = ''
    state.carregado = false
  }
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo]?.[indice]?.propriedades?.[elemento] || {}
}

watch(
  () => [state.html, state.codigoHtml, state.codigoCss, state.codigoJs],
  ([html, htmlCode, cssCode, jsCode]) => {
    if (html !== null || htmlCode !== null || cssCode !== null || jsCode !== null) {
      editarText()
    }
  }
)

watchEffect(() => {
  carregarText()
})
</script>

<style scoped>
.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.text.maior {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--cor-cinza-2);
  padding: 30px;
  position: fixed;
  top: 0;
  right: 0;
  width: 600px;
  height: 100dvh;
  z-index: 9;
}

.text.maior h3 {
  font-size: var(--f2);
  width: 100%;
  max-width: 350px;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  line-height: 1.5;
}

.text.maior button.zoom {
  position: fixed;
  top: 5px;
  right: 30px;
}

.text.maior button.zoom svg {
  margin: 0;
}

button.zoom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

button.zoom:hover {
  background-color: var(--cor-cinza-4);
}

button.zoom svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

button.zoom p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.abas {
  display: flex;
  width: 100%;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  margin: 20px 0 0 0;
}

.text.maior .abas button {
  flex-direction: row;
}

.text.maior .abas button svg {
  margin: 0 5px 0 0;
}

.abas button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  background-color: transparent;
  padding: 15px 0;
  transition: all 0.3s;
}

.abas button.css {
  border-left: 1px solid var(--cor-cinza-4);
  border-right: 1px solid var(--cor-cinza-4);
}

.abas button.html {
  border-radius: 10px 0 0 10px;
}

.abas button.html.ativo {
  background-color: var(--cor-cinza-4);
}

.abas button.css.ativo {
  background-color: var(--cor-cinza-4);
}

.abas button.js.ativo {
  background-color: var(--cor-cinza-4);
}

.abas button.js {
  border-radius: 0 10px 10px 0;
}

.abas button svg {
  width: 13px;
  min-width: 13px;
  margin: 0 0 10px 0;
}

.abas button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.editores {
  width: 100%;
}

.editor {
  width: 100%;
  height: 350px;
  min-height: 350px;
  max-height: 350px;
  margin: 20px 0 0 0;
}

.text.maior .editor {
  max-height: calc(100dvh - 190px);
  min-height: calc(100dvh - 190px);
  height: calc(100dvh - 190px);
}

@media screen and (max-width: 1024px) {
  .text.maior {
    padding: 20px;
    width: 100%;
  }
  .text.maior button.zoom {
    position: fixed;
    top: 0;
    right: 20px;
  }
}
</style>
