import { defineStore } from 'pinia'

export const useStoreSecao6 = defineStore('storeSecao6', {
  state: () => ({
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '0D0F1E',
      image: 'https://upload.unicpages.com/imagens/6103144dd0019c1e3c1829-background.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 120,
        right: 80,
        bottom: 140,
        left: 80
      },
      gradienActive: false
    },
    fundoTexto: {
      id: '',
      hidden: true,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    divisor: {
      id: '',
      hidden: true,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png'
    },
    iconeTag: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTag: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    titulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 60,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o titulo para você preencher'
    },
    subtitulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },
    botao: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    },
    fundoForm: {
      id: 'quadroForm',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '16182990',
      maxWidth: 500,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 50,
        right: 40,
        bottom: 50,
        left: 40
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      gradienActive: false
    },
    iconeTagForm: {
      id: '',
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoTagForm: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 300,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloForm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 600',
      fontSize: 30,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: 'D69D3A',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Lorem *Ipsum*'
    },
    subtituloForm: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição para esse formulário integrado com o Discord para receber os dados em um canal'
    },
    botaoForm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 600',
      fontSize: 17,
      color: 'FFFFFF',
      spanBackground: 'FFFFFF00',
      spanColor: 'FFFFFF',
      backgroundColor: 'D69D3A',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 70,
        bottom: 20,
        left: 70
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem Ipsum',
      link: 'Link webhook discord aqui',
      linkTarget: '_self',
      gradienActive: false
    },
    labelUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Lorem Ipsum'
    },
    inputUm: {
      id: 'quadroTime',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '6C6F85',
      backgroundColor: '0E101F90',
      textAlign: 'left',
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 15,
        right: 15,
        bottom: 15,
        left: 15
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'John Smith'
    },
    labelDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Lorem Ipsum'
    },
    inputDois: {
      id: 'quadroTime',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '6C6F85',
      backgroundColor: '0E101F90',
      textAlign: 'left',
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 15,
        right: 15,
        bottom: 15,
        left: 15
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem Ipsum'
    },
    labelTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Lorem Ipsum'
    },
    inputTres: {
      id: 'quadroTime',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '6C6F85',
      backgroundColor: '0E101F90',
      textAlign: 'left',
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 15,
        right: 15,
        bottom: 15,
        left: 15
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem Ipsum'
    },
    modal: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '0D0F1E',
      image: 'https://upload.unicpages.com/imagens/6103144dd0019c1e3c1829-background.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 100,
        right: 80,
        bottom: 100,
        left: 80
      },
      gradienActive: false
    },
    fundoTextoModal: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    iconeTagModal: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/103144dd0019c1e3c1829f-logo.svg',
      maxWidth: 155,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoTagModal: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloModal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 600',
      fontSize: 50,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: 'D69D3A',
      textAlign: 'center',
      lineHeight: 120,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'We have *received*\nyour message!'
    },
    subtituloModal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'We will get in touch as soon as possible. Thank you for reaching out.'
    },
    botaoModal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 17,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: 'D69D3A',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 70,
        bottom: 20,
        left: 70
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'See our instagram',
      link: '',
      linkTarget: '_self',
      gradienActive: false
    }
  })
})
