import { defineStore } from 'pinia'
import { useStoreAlerta } from './index'
import apiTemplates from '../api/unicpages/api-templates'

export const useStoreTemplates = defineStore('storeTemplates', {
  state: () => {
    return {
      templates: []
    }
  },
  actions: {
    async receberTodos() {
      try {
        const resp = await apiTemplates.receberTodos()
        this.templates = resp
        return true
      } catch (error) {
        this.templates = []
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
