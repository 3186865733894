import { defineStore } from 'pinia'

export const useStoreSecao8 = defineStore('storeSecao8', {
  state: () => ({
    fundo: {
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '000001ff',
      gradientActive: true,
      gradientPrimary: '0000014a',
      gradientSecondary: '000001f5',
      gradientDeg: 1,
      image: 'https://upload.unicpages.com/imagens/9df134a93c3fa804ecad8f-desktop.png',
      padding: {
        top: 120,
        right: 80,
        bottom: 120,
        left: 80
      },
      gradienActive: true
    },
    fundoTexto: {
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    divisor: {
      hidden: true,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png'
    },
    imagemAnimadaUm: {
      hidden: true,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/circulo-animado.png',
      maxWidth: 600,
      animationName: 'um',
      animationDuration: 5,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    imagemAnimadaDois: {
      hidden: true,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/circulo-animado.png',
      maxWidth: 600,
      animationName: 'um',
      animationDuration: 5,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    imagemPrincipal: {
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 600,
      margin: {
        top: 120,
        right: 80,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    iconeTag: {
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/2d9df134a93c3fa804ecad-icone.png',
      maxWidth: 193,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoTag: {
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    titulo: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 700',
      fontSize: 50,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '00D535',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 514,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'LOREM *IPSUM SIT AMET DOLOR* IPSUM BRA SIT'
    },
    subtitulo: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 15,
      color: 'FFFFFFB0',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 400,
      margin: {
        top: 15,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis, eros vel gravida elementum'
    },
    botao: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 600',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: 'FFFFFF',
      spanColor: 'FFFFFF',
      backgroundColor: '00D535',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 40,
        bottom: 20,
        left: 40
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: '**Lorem Ipsum**',
      link: 'https://wa.me',
      linkTarget: '_blank',
      gradienActive: false
    }
  })
})
