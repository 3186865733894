import { defineStore } from 'pinia'

export const useStoreHtml2Mobile = defineStore('storeHtml2Mobile', {
  state: () => ({
    html: {
      hidden: false,
      positionFixed: false,
      codigoHtml: `
<section class="apresentacao">
  <h1>Seção em código completo</h1>
  <p>Crie com HTML, CSS e JavaScript</p>
</section>`,
      codigoCss: `
section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #000;
}
section h1 {
  font-size: 30px;
  line-height: 1;
  color: #fff;
}
section p {
  font-size: 18px;
  color: #fff;
}`,
      codigoJs: `
const section = document.querySelector('section.apresentacao')
      `
    }
  })
})
