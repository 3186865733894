import { defineStore } from 'pinia'

export const useStoreSecao4 = defineStore('storeSecao4', {
  state: () => ({
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '0D0F1E',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 40,
        right: 80,
        bottom: 150,
        left: 80
      },
      gradienActive: false
    },
    fundoItens: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    divisor: {
      id: '',
      hidden: false,
      image: 'https://upload.unicpages.com/imagens/f5c8300dc8c3bb1c92907d-divisor.png'
    },
    fundoTitulo: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 40,
        left: 0
      }
    },
    tituloIconeTag: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/0cd006d6103144dd0019c1-coroa-branco.svg',
      maxWidth: 80,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    tituloTextoTag: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloPrincipal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 600',
      fontSize: 56,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: 'D69D3A',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 1900,
      margin: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem *ipsum dolor* sit amet, consectetur '
    },
    tituloSubtitulo: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 1900,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível usando a UnicPages no seu site'
    },
    tituloBotao: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 50,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    },
    fundoQuadroUm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      backgroundColor: '161829',
      image: 'https://upload.unicpages.com/imagens/d006d6103144dd0019c1e3-quadro-1.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      },
      gradienActive: false
    },
    iconeTagUm: {
      id: '',
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 15,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTagUm: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 600',
      fontSize: 30,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Lorem Big\nIpsum'
    },
    subtituloUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 15,
      color: 'ADB0C3',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis, eros vel gravida elementum, nisl sapien dignissim erat, sed condimentum erat arcu at lectus. Nullam non dictum ex, id aliquet nunc. Vivamus sit amet orci dapibus.'
    },
    botaoUm: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 16,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    },
    fundoQuadroDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      backgroundColor: '161829',
      image: 'https://upload.unicpages.com/imagens/8300dc8c3bb1c92907d53b-quadro-2.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      },
      gradienActive: false
    },
    iconeTagDois: {
      id: '',
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 15,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTagDois: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 600',
      fontSize: 30,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Lorem Big\nIpsum'
    },
    subtituloDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 15,
      color: 'ADB0C3',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis, eros vel gravida elementum, nisl sapien dignissim erat, sed condimentum erat arcu at lectus. Nullam non dictum ex, id aliquet nunc. Vivamus sit amet orci dapibus.'
    },
    botaoDois: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 16,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    },
    fundoQuadroTres: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      backgroundColor: '161829',
      image: 'https://upload.unicpages.com/imagens/cd006d6103144dd0019c1e-quadro-3.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      },
      gradienActive: false
    },
    iconeTagTres: {
      id: '',
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 15,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTagTres: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 600',
      fontSize: 30,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Lorem Big\nIpsum'
    },
    subtituloTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 15,
      color: 'ADB0C3',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis, eros vel gravida elementum, nisl sapien dignissim erat, sed condimentum erat arcu at lectus. Nullam non dictum ex, id aliquet nunc. Vivamus sit amet orci dapibus.'
    },
    botaoTres: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 16,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    },
    fundoQuadroQuatro: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      backgroundColor: '161829',
      image: 'https://upload.unicpages.com/imagens/c8300dc8c3bb1c92907d53-quadro-4.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 10,
        bottom: 20,
        left: 10
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      padding: {
        top: 120,
        right: 40,
        bottom: 40,
        left: 40
      },
      gradienActive: false
    },
    iconeTagQuatro: {
      id: '',
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 15,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTagQuatro: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 600',
      fontSize: 30,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Lorem Big\nIpsum'
    },
    subtituloQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 15,
      color: 'ADB0C3',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis, eros vel gravida elementum, nisl sapien dignissim erat, sed condimentum erat arcu at lectus. Nullam non dictum ex, id aliquet nunc. Vivamus sit amet orci dapibus.'
    },
    botaoQuatro: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 16,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    }
  })
})
