<template>
  <section class="modal" :class="{ mostrar: modalAberto }" ref="scrollModal">
    <div class="background"></div>
    <div class="titulo maior">
      <Svgs :nome="dadosModal.icone" />
      <h3>{{ dadosModal.titulo }}</h3>
      <button class="fechar" @click="fecharModal(props.nome)">
        <Svgs nome="x" />
      </button>
    </div>
    <div class="conteudo">
      <div class="titulo">
        <Svgs :nome="dadosModal.icone" />
        <h3>{{ dadosModal.titulo }}</h3>
        <button class="fechar" @click="fecharModal(props.nome)">
          <Svgs nome="x" />
        </button>
      </div>
      <div class="dados">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal } from '@stores'
import { computed, ref, watch, onMounted, onUnmounted, inject } from 'vue'
import Svgs from '@svgs'

const props = defineProps({
  nome: {
    type: String,
    required: true
  }
})

const emitter = inject('emitter')
const storeModal = useStoreModal()
const modalAberto = computed(() => storeModal.modalAberto(props.nome))
const dadosModal = computed(() => storeModal.dadosDoModal(props.nome))

const scrollModal = ref(null)

const resetarScroll = () => {
  if (scrollModal.value) {
    scrollModal.value.scrollTop = 0
  }
}

function fecharModal() {
  storeModal.fecharModal(props.nome)
  emitter.emit('limparCampos')
}

function abrirModal() {
  emitter.emit('carregarCampos')
}

const handleEscape = (event) => {
  if (event.key === 'Escape' && modalAberto.value) {
    fecharModal()
  }
}

onMounted(() => {
  document.addEventListener('keydown', handleEscape)
})

onUnmounted(() => {
  document.removeEventListener('keydown', handleEscape)
})

watch(modalAberto, (novoValor) => {
  if (novoValor) {
    abrirModal()
    resetarScroll()
  }
})
</script>

<style scoped>
section.modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  min-height: 100dvh;
  max-height: 100dvh;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow-y: scroll;
}

section.modal.modalChatComIa {
  padding: 0 0 100px 0;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

section.modal.mostrar .background {
  opacity: 1;
  visibility: visible;
}

section.modal.mostrar button.fechar {
  top: 40px;
  transform: rotate(0deg);
}

section.modal.mostrar .conteudo {
  transform: translate(0px, 0px);
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--cor-preto-transparente);
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s;
}

button.fechar {
  border-radius: 50%;
  background-color: transparent;
  transform: rotate(15deg);
  margin-left: auto;
  z-index: 13;
  transition: all 0.6s;
}

button.fechar:hover svg {
  fill: var(--cor-vermelho);
}

button.fechar svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-branco);
  margin: 0;
  transition: all 0.3s;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--cor-cinza-2);
  transform: translate(0px, -300px);
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  margin: 40px 0 40px 0;
  position: relative;
  z-index: 12;
  transition: all 0.6s;
}

section.modal.maior .conteudo {
  max-width: 1100px;
  margin: 0;
}

section.modal.template .titulo {
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: none;
  z-index: 3;
  padding: 20px;
  background-color: transparent;
}

section.modal .titulo.maior {
  display: none;
}

section.modal.maior .titulo.maior {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1100px;
  border-radius: 0;
  z-index: 14;
}

section.modal.maior .titulo {
  display: none;
}

section.modal.template .dados {
  padding: 0;
}

section.modal.maior .dados {
  padding: 30px 20px 30px 30px;
}

.titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-bottom: 1px solid var(--cor-cinza-3);
  background-color: var(--cor-cinza-2);
  border-radius: 10px 10px 0 0;
}

.titulo svg {
  width: 20px;
  min-width: 20px;
  max-height: 20px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.titulo h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

section.modal.icones .dados {
  padding: 0;
}

.dados {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 30px;
  opacity: 0;
  visibility: hidden;
}

section.modal.mostrar .dados {
  opacity: 1;
  visibility: visible;
  animation: aparecendo 0.6s linear;
}

@keyframes aparecendo {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 1024px) {
  section.modal.modalChatComIa {
    padding: 0;
  }
  section.modal.modalChatComIa .conteudo {
    padding: 0 0 100px 0;
  }
  .conteudo {
    margin: 0;
    border-radius: 0;
  }
  .titulo {
    padding: 20px;
  }
  .dados {
    padding: 20px;
  }
  section.modal.maior .dados {
    padding: 20px;
  }
}
</style>
