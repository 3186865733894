<template>
  <section class="modal" :class="{ mostrar: modalAberto }" ref="scrollModal">
    <div class="background"></div>
    <div class="conteudo">
      <button class="fechar" @click="fecharModal('verVideo')">
        <Svgs nome="x" />
      </button>
      <div class="dados">
        <div class="video">
          <div class="tamanho">
            <iframe :src="dadosModal.urlVideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
        <div class="texto">
          <h3>{{ dadosModal.titulo }}</h3>
          <p>{{ dadosModal.descricao }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal } from '@stores'
import { computed, ref, watch, onMounted, onUnmounted, inject } from 'vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeModal = useStoreModal()
const modalAberto = computed(() => storeModal.modalAberto('verVideo'))
const dadosModal = computed(() => storeModal.dadosDoModal('verVideo'))

const scrollModal = ref(null)

const resetarScroll = () => {
  if (scrollModal.value) {
    scrollModal.value.scrollTop = 0
  }
}

function fecharModal() {
  storeModal.fecharModal('verVideo')
  emitter.emit('limparCampos')
}

function abrirModal() {
  emitter.emit('carregarCampos')
}

const handleEscape = (event) => {
  if (event.key === 'Escape' && modalAberto.value) {
    fecharModal()
  }
}

onMounted(() => {
  document.addEventListener('keydown', handleEscape)
})

onUnmounted(() => {
  document.removeEventListener('keydown', handleEscape)
})

watch(modalAberto, (novoValor) => {
  if (novoValor) {
    abrirModal()
    resetarScroll()
  }
})
</script>

<style scoped>
section.modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  min-height: 100dvh;
  max-height: 100dvh;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow-y: scroll;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

section.modal.mostrar .background {
  opacity: 1;
  visibility: visible;
}

section.modal.mostrar button.fechar {
  top: 40px;
  transform: rotate(0deg);
}

section.modal.mostrar .conteudo {
  transform: translate(0px, 0px);
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000000d5;
  z-index: 11;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s;
}

button.fechar {
  border-radius: 50%;
  background-color: transparent;
  transform: rotate(15deg);
  margin-left: auto;
  position: absolute;
  top: 0;
  right: -60px;
  z-index: 13;
  transition: all 0.6s;
}

button.fechar:hover svg {
  fill: var(--cor-vermelho);
}

button.fechar svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-branco);
  margin: 0;
  transition: all 0.3s;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--cor-cinza-2);
  transform: translate(0px, -300px);
  width: 100%;
  max-width: 800px;
  border-radius: 10px;
  margin: 40px 0 40px 0;
  position: relative;
  z-index: 12;
  transition: all 0.6s;
}

.video {
  width: 100%;
}

.video .tamanho {
  position: relative;
  padding-bottom: 56.52%;
  width: 100%;
}

.video .tamanho iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  border-radius: 10px 10px 0 0;
}

.dados {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}

.dados .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 30px;
}

.dados .texto h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
}

.dados .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

section.modal.mostrar .dados {
  opacity: 1;
  visibility: visible;
  animation: aparecendo 0.6s linear;
}

@keyframes aparecendo {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 1024px) {
  .conteudo {
    margin: 60px 20px;
  }
  .dados .texto {
    padding: 20px;
  }
  .dados .texto h3 {
    font-size: var(--f3);
  }
  .dados .texto p {
    font-size: var(--f2);
  }

  button.fechar {
    right: -5px;
    top: -30px !important;
  }

  button.fechar svg {
    width: 20px;
    min-width: 20px;
  }
}
</style>
