<template>
  <div class="particles">
    <canvas ref="canvasRef"></canvas>
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { createNoise3D } from 'simplex-noise'

const canvasRef = ref(null)
const noise3D = createNoise3D()
const particleCount = 200
const particles = []
const TAU = 5 * Math.PI
const xOff = 0.002
const yOff = 0.002
const zOff = 0.002
let tick = 0

const props = defineProps({
  colorTheme: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'free', 'silver', 'gold', 'diamond', 'unlimited', 'erro'].includes(value)
  }
})

const themes = {
  default: ['#3EBDF9', '#2665FD', '#7042F6', '#F841C7', '#FE3A50'],
  free: ['#21E664', '#1DD859', '#1ACA4E', '#17BC43', '#13AE38'],
  silver: ['#8E8FAB', '#7E7F9B', '#6A6B89', '#585978', '#464867'],
  gold: ['#FFCE20', '#E6BA1D', '#CCA61A', '#B39217', '#997E14'],
  diamond: ['#2CCCFF', '#28B8E6', '#24A4CC', '#2090B3', '#187199'],
  unlimited: ['#9039FF', '#722FFF', '#5A26E6', '#411FCC', '#2B19B3'],
  erro: ['#FF6B6B', '#FF5252', '#E63939', '#CC2121', '#B31919']
}

const particleColors = ref(themes[props.colorTheme])

watch(
  () => props.colorTheme,
  (newTheme) => {
    particleColors.value = themes[newTheme] || themes.default
    resetParticles()
  }
)

function initParticles(canvas) {
  particles.length = 0
  for (let i = 0; i < particleCount; i++) {
    particles.push({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      speed: Math.random() * 1.5 + 0.5,
      direction: Math.random() * Math.PI * 2,
      radius: Math.random() * 2 + 1,
      color: particleColors.value[Math.floor(Math.random() * particleColors.value.length)]
    })
  }
}

function resetParticles() {
  const canvas = canvasRef.value
  if (!canvas) return
  initParticles(canvas)
}

function draw(ctx, canvas) {
  tick++
  ctx.clearRect(0, 0, canvas.width, canvas.height)

  particles.forEach((p) => {
    const noise = noise3D(p.x * xOff, p.y * yOff, tick * zOff) * TAU
    p.x += Math.cos(p.direction + noise) * p.speed
    p.y += Math.sin(p.direction + noise) * p.speed

    if (p.x < 0 || p.x > canvas.width || p.y < 0 || p.y > canvas.height) {
      p.x = Math.random() * canvas.width
      p.y = Math.random() * canvas.height
    }

    ctx.save()
    ctx.globalCompositeOperation = 'lighter'
    ctx.fillStyle = p.color
    ctx.beginPath()
    ctx.arc(p.x, p.y, p.radius, 0, Math.PI * 2)
    ctx.fill()
    ctx.restore()
  })

  requestAnimationFrame(() => draw(ctx, canvas))
}

function resizeCanvas(canvas) {
  canvas.width = window.innerWidth
  canvas.height = window.innerHeight
}

onMounted(() => {
  const canvas = canvasRef.value
  if (!canvas) return
  const ctx = canvas.getContext('2d')
  resizeCanvas(canvas)
  initParticles(canvas)
  draw(ctx, canvas)

  window.addEventListener('resize', () => resizeCanvas(canvas))
})

onUnmounted(() => {
  window.removeEventListener('resize', () => resizeCanvas(canvasRef.value))
})
</script>

<style>
.particles canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 2;
}
.particles.flat canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 2;
}
</style>
