<template>
  <section class="entrar">
    <Particles colorTheme="default" />
    <div class="conteudo">
      <h1>O construtor de sites para quem busca facilidade, escala e velocidade 🏆</h1>
      <div class="quadro">
        <div class="titulo">
          <Svgs nome="logo-flat" />
          <p>Faça login para entrar em sua conta Unic</p>
        </div>
        <div class="input">
          <Svgs class="icone" nome="envelope" />
          <input type="email" spellCheck="false" placeholder="email@email.com" autoComplete="email" v-model="state.email" @keyup.enter="entrar()" />
        </div>
        <div class="input">
          <Svgs class="icone" nome="cadeado" />
          <input :type="state.mostrarSenha ? 'text' : 'password'" spellCheck="false" placeholder="senha#123" autoComplete="current-password" v-model="state.senha" @keyup.enter="entrar()" />
          <button class="ocultar" @click="toggleMostrarSenha">
            <Svgs :nome="state.mostrarSenha ? 'oculto' : 'visivel'" />
          </button>
        </div>
        <button class="entrar" :disabled="state.desabilitarBotao" @click="entrar()">
          Entrar Agora
          <span></span>
        </button>
        <div class="opcoes">
          <a href="https://wa.me/551831993895">Esqueceu sua senha?</a>
          <router-link to="/registrar">Não tem conta? Crie grátis</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { inject, reactive } from 'vue'
import { helperValidacoes } from '@helpers'
import apiAutenticacao from '@/api/unicpages/api-autenticacao'
import Particles from '@components/global/particles/Particles.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')

const state = reactive({
  email: '',
  senha: '',
  mostrarSenha: false,
  desabilitarBotao: false
})

const toggleMostrarSenha = () => {
  state.mostrarSenha = !state.mostrarSenha
}

function validarDados() {
  let mensagem
  if (!state.email || !state.senha) mensagem = 'Por favor preencha todos os campos'
  else if (!helperValidacoes.email(state.email)) mensagem = 'Email digitado está no formato incorreto'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

async function entrar() {
  if (!validarDados()) return

  const payload = {
    email: state.email,
    senha: state.senha
  }

  try {
    state.desabilitarBotao = true
    const resp = await apiAutenticacao.entrar(payload)
    localStorage.setItem('unicpages-app-access-token', resp.token)
    setTimeout(() => window.location.assign('/sites'), 300)
  } catch (error) {
    state.desabilitarBotao = false
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: error })
  }
}
</script>

<style scoped>
section.entrar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: var(--cor-cinza-1);
}

.conteudo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 100dvh;
  z-index: 3;
}

h1 {
  font-family: var(--bold);
  font-size: var(--f5);
  color: var(--cor-branco);
  width: 100%;
  max-width: 400px;
  line-height: 1.3;
  margin: 0 50px 0 0;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--cor-cinza-1);
  border: 1px solid var(--cor-cinza-3);
  padding: 60px 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 450px;
}

.conteudo .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conteudo .titulo svg {
  width: 150px;
  min-width: 150px;
  margin: 0 0 15px 0;
}

.conteudo .titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 300px;
}

.conteudo .input {
  margin: 20px 0 0 0;
}

.conteudo button.entrar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  width: 100%;
  max-width: 400px;
  padding: 20px 20px;
  border-radius: 10px;
  margin: 20px 0 0 0;
  position: relative;
  transition: all 0.3s;
}

.conteudo button.entrar:hover {
  background-color: var(--cor-azul-escuro);
}

.conteudo button.entrar:disabled {
  color: transparent;
}

.conteudo button.entrar:disabled span {
  opacity: 1;
  visibility: visible;
}

.conteudo button.entrar span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco-fixo);
  border-top: 2px solid transparent;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: girando 0.5s linear infinite;
  pointer-events: none;
  transition: all 0.3s;
}

.conteudo .opcoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  margin: 10px 0 0 0;
  padding: 20px 0 0 0;
}

.conteudo .opcoes a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  background-color: transparent;
  transition: all 0.3s;
}

.conteudo .opcoes a:hover {
  color: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.entrar {
    display: flex;
    flex-direction: column-reverse;
  }

  .conteudo {
    width: 100%;
    padding: 50px 20px;
  }

  h1 {
    display: none;
  }

  .quadro {
    padding: 50px 25px;
  }

  .quadro .titulo img {
    max-width: 100px;
  }

  .quadro .titulo p {
    font-size: var(--f1);
  }

  .conteudo .opcoes {
    flex-direction: column;
  }

  .conteudo .opcoes a {
    font-size: var(--f0);
    margin: 0 0 15px 0;
  }
}
</style>
