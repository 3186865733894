<template>
  <p v-if="exibirTexto">{{ textoExibido }}</p>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'

// Props
const props = defineProps({
  texto: {
    type: String,
    required: true
  },
  tempoTotal: {
    type: Number,
    required: true
  },
  ativo: {
    type: Boolean,
    required: true
  },
  finalizado: {
    type: Function,
    required: false
  }
})

const textoExibido = ref('')
const exibirTexto = ref(false)

function iniciarDigitacao() {
  if (!props.ativo) return

  textoExibido.value = ''
  exibirTexto.value = true

  const intervaloTempo = props.tempoTotal / props.texto.length
  let index = 0

  const intervalo = setInterval(() => {
    if (index < props.texto.length) {
      textoExibido.value += props.texto[index]
      index++
    } else {
      clearInterval(intervalo)
      if (props.finalizado) {
        props.finalizado(true)
        scrollParaFinalModal()
      }
    }
  }, intervaloTempo)
}

function scrollParaFinalModal() {
  const modal = document.querySelector('.modalChatComIa')
  if (modal) {
    modal.scrollTo({
      top: modal.scrollHeight,
      behavior: 'smooth'
    })
  } else {
    console.warn('Elemento modal não encontrado.')
  }
}

watch(
  () => props.ativo,
  (novoAtivo) => {
    if (novoAtivo) iniciarDigitacao()
  }
)

onMounted(() => {
  if (props.ativo) iniciarDigitacao()
})
</script>
