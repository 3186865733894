<template>
  <section class="planos">
    <!-- Titulo -->
    <div class="titulo">
      <div class="texto">
        <h3>Seus sites estão offline</h3>
        <p>Não foi possível processar o pagamento da sua assinatura Unic e por esse motivo seus sites foram tirados do ar. Para reativá-los assine novamente e volte a criar sites incríveis.</p>
      </div>
      <div class="toggle" :class="{ ativo: state.mensalAtivo }" @click="togglePeriodo">
        <div class="parte um">
          <h3>Anual</h3>
        </div>
        <div class="parte dois">
          <h3>Mensal</h3>
        </div>
        <span></span>
        <p>DESCONTO 34%</p>
      </div>
    </div>
    <!-- Itens -->
    <div class="itens" :class="{ ativo: state.mensalAtivo, [storePerfil.usuario?.assinatura?.plano]: true }">
      <div v-for="(plano, index) in planos" :key="index" :class="'item ' + plano.class">
        <div class="nome">
          <img :src="plano.simbolo" class="simbolo" />
          <h3>{{ plano.nome }}</h3>
        </div>
        <div class="preco">
          <div v-for="(preco, tipo) in plano.precos" :key="tipo" :class="tipo">
            <div class="valor">
              <h2>{{ preco.valor }}</h2>
              <div class="parte">
                <h4>{{ preco.texto }}</h4>
                <h6>{{ preco.detalhe }}</h6>
              </div>
            </div>
            <h5>
              {{ preco.cheio }}
              <span>{{ preco.desconto }}</span>
            </h5>
          </div>
        </div>
        <div class="beneficios">
          <div class="beneficio" :class="{ bloqueado: !item.liberado }" v-for="item in plano.beneficios">
            <div class="icone">
              <Svgs :nome="item.icon" />
            </div>
            <p>{{ item.titulo }}</p>
          </div>
        </div>
        <a :href="plano.botao.linkBotao" :class="plano.botao.class" target="_blank">
          <img :src="plano.botao.icon" />
          <p>{{ plano.botao.texto }}</p>
        </a>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, computed, watch } from 'vue'
import { useStorePerfil } from '@stores'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()

const state = reactive({
  mensalAtivo: false,
  nomeUsuario: '',
  emailUsuario: '',
  telefoneUsuario: ''
})

const parametrosUsuario = computed(() => {
  const nome = encodeURIComponent(state.nomeUsuario || '')
  const email = encodeURIComponent(state.emailUsuario || '')
  const telefone = encodeURIComponent(state.telefoneUsuario || '')
  return `email=${email}&name=${nome}&phone=${telefone}`
})

const atualizarBotoes = () => {
  const tipoPlano = state.mensalAtivo ? 'mensal' : 'anual'
  planos.forEach((plano) => {
    const baseLink = plano.botao.link[tipoPlano]
    plano.botao.linkBotao = baseLink ? `${baseLink}?${parametrosUsuario.value}` : ''
    plano.botao.texto = `Assinar ${plano.class} ${tipoPlano.charAt(0).toUpperCase() + tipoPlano.slice(1)}`
  })
}

const togglePeriodo = () => {
  state.mensalAtivo = !state.mensalAtivo
  atualizarBotoes()
}

const planos = reactive([
  // Free
  {
    class: 'free',
    simbolo: 'https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-free.svg',
    nome: 'Plano Free',
    precos: {
      anual: {
        valor: 'R$ 0',
        texto: 'grátis',
        detalhe: 'vitalício',
        cheio: 'Sem cobranças',
        desconto: ''
      },
      mensal: {
        valor: 'R$ 0',
        texto: 'grátis',
        detalhe: 'vitalício',
        cheio: 'Sem cobranças',
        desconto: ''
      }
    },
    beneficios: [
      { icon: 'cursor', liberado: true, titulo: 'Crie até 1 site' },
      { icon: 'dominio', liberado: true, titulo: 'Domínio padrão unic.page' },
      { icon: 'usuarios', liberado: true, titulo: '2.000 acessos por mês' },
      { icon: 'editar', liberado: true, titulo: 'Personalização ilimitada' },
      { icon: 'cadeado', liberado: true, titulo: 'Certificado SSL grátis já ativado' },
      { icon: 'icon', liberado: false, titulo: 'Remover marca d`gua' },
      { icon: 'unicflix-icone', liberado: false, titulo: 'UnicFlix' },
      { icon: 'icones', liberado: false, titulo: 'Banco de Ícones' }
    ],
    botao: {
      link: {
        mensal: 'https://api.whatsapp.com/send?phone=1831993895&text=Ol%C3%A1%2C%20gostaria%20de%20migrar%20para%20o%20Plano%20Free%20da%20UnicPages!',
        anual: 'https://api.whatsapp.com/send?phone=1831993895&text=Ol%C3%A1%2C%20gostaria%20de%20migrar%20para%20o%20Plano%20Free%20da%20UnicPages!'
      },
      linkBotao: 'https://api.whatsapp.com/send?phone=1831993895&text=Ol%C3%A1%2C%20gostaria%20de%20migrar%20para%20o%20Plano%20Free%20da%20UnicPages!',
      icon: '',
      texto: 'Migrar para Free'
    }
  },
  // Silver
  {
    class: 'silver',
    simbolo: 'https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-silver.svg',
    nome: 'Plano Silver',
    precos: {
      anual: {
        valor: 'R$ 38',
        texto: '/ mês',
        detalhe: 'Cobrado Anualmente',
        cheio: 'ou R$ 388 à vista |',
        desconto: 'R$ 200 de desconto'
      },
      mensal: {
        valor: 'R$ 49',
        texto: '/ mês',
        detalhe: 'Cobrado Mensalmente',
        cheio: 'Assinatura Recorrente',
        desconto: ''
      }
    },
    beneficios: [
      { icon: 'cursor', liberado: true, titulo: 'Crie até 3 sites' },
      { icon: 'dominio', liberado: true, titulo: 'Configure até 3 domínios' },
      { icon: 'infinito', liberado: true, titulo: 'Acessos ilimitados em seus sites' },
      { icon: 'editar', liberado: true, titulo: 'Personalização ilimitada' },
      { icon: 'cadeado', liberado: true, titulo: 'Certificado SSL grátis já ativado' },
      { icon: 'icon', liberado: true, titulo: 'Remover marca d’água' },
      { icon: 'unicflix-icone', liberado: true, titulo: 'UnicFlix' },
      { icon: 'icones', liberado: true, titulo: 'Banco de Ícones' }
    ],
    botao: {
      link: {
        mensal: 'https://pay.kiwify.com.br/zTxLuu1',
        anual: 'https://pay.kiwify.com.br/45fp0Vz'
      },
      linkBotao: 'https://pay.kiwify.com.br/45fp0Vz',
      icon: 'https://arquivos.unicpages.com/imagens/app/planos/icones/icone-branco-silver.svg',
      texto: 'Assinar Silver Anual'
    }
  },
  // Gold
  {
    class: 'gold',
    simbolo: 'https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-gold.svg',
    nome: 'Plano Gold',
    precos: {
      anual: {
        valor: 'R$ 78',
        texto: '/ mês',
        detalhe: 'Cobrado Anualmente',
        cheio: 'ou R$ 784 à vista |',
        desconto: 'R$ 400 de desconto'
      },
      mensal: {
        valor: 'R$ 99',
        texto: '/ mês',
        detalhe: 'Cobrado Mensalmente',
        cheio: 'Assinatura Recorrente',
        desconto: ''
      }
    },
    beneficios: [
      { icon: 'cursor', liberado: true, titulo: 'Crie até 10 sites' },
      { icon: 'dominio', liberado: true, titulo: 'Configure até 10 domínios' },
      { icon: 'infinito', liberado: true, titulo: 'Acessos ilimitados em seus sites' },
      { icon: 'editar', liberado: true, titulo: 'Personalização ilimitada' },
      { icon: 'cadeado', liberado: true, titulo: 'Certificado SSL grátis já ativado' },
      { icon: 'icon', liberado: true, titulo: 'Remover marca d’água' },
      { icon: 'unicflix-icone', liberado: true, titulo: 'UnicFlix' },
      { icon: 'icones', liberado: true, titulo: 'Banco de Ícones' }
    ],
    botao: {
      link: {
        mensal: 'https://pay.kiwify.com.br/rGIAr6o',
        anual: 'https://pay.kiwify.com.br/4943CNo'
      },
      linkBotao: 'https://pay.kiwify.com.br/4943CNo',
      icon: 'https://arquivos.unicpages.com/imagens/app/planos/icones/icone-branco-gold.svg',
      texto: 'Assinar Gold Anual'
    }
  },
  // Diamond
  {
    class: 'diamond',
    simbolo: 'https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-diamond.svg',
    nome: 'Plano Diamond',
    precos: {
      anual: {
        valor: 'R$ 206',
        texto: '/ mês',
        detalhe: 'Cobrado Anualmente',
        cheio: 'ou R$ 2.052 à vista |',
        desconto: 'R$ 1.056 de desconto'
      },
      mensal: {
        valor: 'R$ 259',
        texto: '/ mês',
        detalhe: 'Cobrado Mensalmente',
        cheio: 'Assinatura Recorrente',
        desconto: ''
      }
    },
    beneficios: [
      { icon: 'cursor', liberado: true, titulo: 'Crie até 50 sites' },
      { icon: 'dominio', liberado: true, titulo: 'Configure até 50 domínios' },
      { icon: 'infinito', liberado: true, titulo: 'Acessos ilimitados em seus sites' },
      { icon: 'editar', liberado: true, titulo: 'Personalização ilimitada' },
      { icon: 'cadeado', liberado: true, titulo: 'Certificado SSL grátis já ativado' },
      { icon: 'icon', liberado: true, titulo: 'Remover marca d’água' },
      { icon: 'unicflix-icone', liberado: true, titulo: 'UnicFlix' },
      { icon: 'icones', liberado: true, titulo: 'Banco de Ícones' }
    ],
    botao: {
      link: {
        mensal: 'https://pay.kiwify.com.br/rnKC38X',
        anual: 'https://pay.kiwify.com.br/9TzuszO'
      },
      linkBotao: 'https://pay.kiwify.com.br/9TzuszO',
      icon: 'https://arquivos.unicpages.com/imagens/app/planos/icones/icone-branco-diamond.svg',
      texto: 'Assinar Diamond Anual'
    }
  }
])

watch(
  () => storePerfil.usuario,
  (usuario) => {
    if (usuario) {
      state.nomeUsuario = usuario.nome || ''
      state.emailUsuario = usuario.email || ''
      state.telefoneUsuario = usuario.contato?.whatsapp || ''
      atualizarBotoes()
    }
  },
  { immediate: true }
)
</script>

<style scoped>
section.planos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 20px 20px;
  width: 100%;
  overflow: hidden;
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 30px 0;
}

.titulo .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.titulo .texto h3 {
  font-family: var(--bold);
  font-size: var(--f6);
  color: var(--cor-branco);
}

.titulo .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 10px 0 20px 0;
  width: 100%;
  max-width: 600px;
}

.titulo .toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 40px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
}

.titulo .toggle .parte {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
  z-index: 2;
}

.titulo .toggle .parte h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  transition: all 0.5s;
}

.titulo .toggle:hover {
  background-color: var(--cor-cinza-3);
}

.titulo .toggle span {
  position: absolute;
  height: 40px;
  right: 100px;
  width: 100px;
  background-color: var(--cor-azul);
  border-radius: 50px;
  z-index: 1;
  transition: all 0.6s;
}

.titulo .toggle p {
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  border-radius: 5px;
  padding: 3px 8px;
  position: absolute;
  left: -60px;
  bottom: -20px;
  transform: rotate(10deg);
}

.titulo .toggle.ativo span {
  width: 100px;
  right: 0;
}

.titulo .toggle .parte.um h3 {
  color: var(--cor-branco-fixo);
}

.titulo .toggle.ativo .parte.um h3 {
  color: var(--cor-branco);
}

.titulo .toggle.ativo .parte.dois h3 {
  color: var(--cor-branco-fixo);
}

/* Itens */
.itens {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 0 0;
}

.itens .item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(25% - 10px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px;
  position: relative;
  transition: all 0.3s;
}

.itens .item .nome {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.itens .item .nome img {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
  z-index: 1;
}

.itens .item .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.itens .item .nome h4 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: #33b0b8;
  padding: 5px 15px;
  border-radius: 50px;
  position: absolute;
  bottom: -15px;
}

/* Preço */
.itens .item .preco {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 15px 0 10px 0;
  position: relative;
  overflow: hidden;
  min-height: 100px;
}

.itens.ativo .item .preco .anual {
  left: -100%;
}

.itens.ativo .item .preco .mensal {
  left: 0;
}

.itens .item .preco .mensal {
  left: 0;
}

.itens.ativo .item .preco .mensal {
  left: 0;
}

.itens .item .preco .anual {
  left: -100%;
}

.itens.ativo .item .preco .anual {
  left: -100%;
}

.itens .item .preco .anual {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  transition: all 0.6s;
}

.itens .item .preco .mensal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  left: 100%;
  width: 100%;
  transition: all 0.6s;
}

.itens .item .preco .valor {
  display: flex;
  align-items: center;
  width: 100%;
}

.itens .item .preco h2 {
  font-family: var(--bold);
  font-size: var(--f5);
  color: var(--cor-branco);
  margin: 0 10px 0 0;
}

.itens .item .preco h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.itens .item .preco h6 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 3px 0 0 0;
}

.itens .item .preco h5 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 10px 0 0 0;
}

.itens .item .preco h5 span {
  font-family: var(--bold);
  color: var(--cor-azul);
}

.itens .item .preco .parte:nth-child(1) {
  align-items: flex-end;
  text-align: right;
}

.itens .item .preco .parte {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Botão */
.itens .item a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-azul);
  padding: 20px 40px;
  border-radius: 10px;
  width: 100%;
  transition: all 0.3s;
}

.itens .item a:hover {
  background-color: var(--cor-azul-escuro);
}

.itens .item.free a:hover {
  background-color: var(--cor-cinza-4);
}

.itens .item.free a {
  background-color: var(--cor-cinza-3);
}

.itens .item.free a img {
  display: none;
}

.itens .item.free a p {
  color: var(--cor-branco);
}

.itens .item a p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  text-transform: capitalize;
}
.itens .item img {
  width: 100%;
  max-width: 15px;
  margin: 0 10px 0 0;
}

/* Benefícios */
.beneficios {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.beneficios .beneficio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 0 20px 0;
}

.beneficios .beneficio.bloqueado p {
  color: var(--cor-cinza-5);
  text-decoration: line-through;
}

.beneficios .beneficio.bloqueado .icone {
  background-color: var(--cor-cinza-2);
  border: 2px solid var(--cor-cinza-3);
}

.beneficios .beneficio.bloqueado .icone svg {
  fill: var(--cor-cinza-4);
}

.beneficios .beneficio p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.beneficios .beneficio .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
}

.beneficios .beneficio .icone svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
}

/*
Responsivo
*/
@media screen and (max-width: 1024px) {
  section.planos {
    padding: 0px 20px 0px 20px;
  }

  .titulo {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 40px 20px;
  }

  .titulo .texto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .titulo .texto h3 {
    font-size: var(--f4);
  }

  .titulo .texto p {
    font-size: var(--f1);
    margin: 10px 0 0 0;
  }

  .titulo .toggle {
    width: 150px;
    height: 30px;
    margin: 20px 0 0 0;
  }

  .titulo .toggle .parte h3 {
    font-size: var(--f1);
  }

  .titulo .toggle span {
    height: 30px;
    right: 75px;
    width: 75px;
  }

  .titulo .toggle p {
    left: -65px;
    bottom: -20px;
    transform: rotate(10deg);
  }

  .titulo .toggle.ativo span {
    width: 75px;
  }

  .titulo .toggle .parte.um h3 {
    color: var(--cor-branco-fixo);
  }

  .titulo .toggle.ativo .parte.um h3 {
    color: var(--cor-branco);
  }

  .titulo .toggle.ativo .parte.dois h3 {
    color: var(--cor-branco-fixo);
  }

  /* Itens */
  .itens {
    flex-direction: column;
    width: 100%;
    margin: 20px 0 0 0;
  }

  .itens .item {
    width: 100%;
    padding: 20px;
    margin: 0 0 20px 0;
  }

  .itens .item .nome img {
    max-width: 25px;
    margin: 0 8px 0 0;
  }

  .itens .item .nome h3 {
    font-size: var(--f2);
  }

  /* Preço */
  .itens .item .preco {
    margin: 10px 0 20px 0;
    min-height: 100px;
  }

  .itens .item .preco .valor {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .itens .item .preco h2 {
    font-family: var(--bold);
    font-size: var(--f5);
    color: var(--cor-branco);
    margin: 0 10px 0 0;
  }

  .itens .item .preco h4 {
    font-size: var(--f1);
  }

  .itens .item .preco h6 {
    font-size: var(--f0);
  }

  .itens .item .preco h5 {
    font-size: var(--f1);
  }

  /* Botão */
  .itens .item a {
    padding: 15px 30px;
    margin: 10px 0 0 0;
  }

  /* Benefícios */

  .beneficios .beneficio {
    margin: 0 0 15px 0;
  }

  .beneficios .beneficio p {
    font-size: var(--f1);
  }

  .beneficios .beneficio .icone {
    width: 25px;
    height: 25px;
  }

  .beneficios .beneficio .icone svg {
    width: 10px;
    min-width: 10px;
    max-height: 10px;
  }
}
</style>
