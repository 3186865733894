import { defineStore } from 'pinia'

export const useStoreSecao15 = defineStore('storeSecao15', {
  state: () => ({
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '0C0C0E',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 80,
        right: 80,
        bottom: 80,
        left: 80
      },
      gradienActive: false
    },
    fundoItens: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    fundoToggle: {
      id: '',
      hidden: false,
      backgroundColor: '202022',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 250,
      maxHeight: 50,
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      gradienActive: false
    },
    spanToggle: {
      id: '',
      hidden: false,
      backgroundColor: '0275FF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 125,
      maxHeight: 50,
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      gradienActive: false
    },
    divisor: {
      id: '',
      hidden: true,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png'
    },
    iconeToggleUm: {
      id: '',
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoToggleUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 15,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Mensal'
    },
    iconeToggleDois: {
      id: '',
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoToggleDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 15,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Anual'
    },
    fundoQuadroUm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      backgroundColor: '141416',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      },
      gradienActive: false
    },
    nomeIconeQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-silver.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    nomeTituloQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 20,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 15
      },
      text: 'Lorem Ipsum'
    },
    precoUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 800',
      fontSize: 50,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 49'
    },
    precoDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 16,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ mês'
    },
    precoTresQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 14,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'cobrado mensalmente'
    },
    precoQuatroQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: '878794',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Assinatura mensal recorrente'
    },
    precoCincoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 800',
      fontSize: 50,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 38'
    },
    precoSeisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 16,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ mês'
    },
    precoSeteQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 14,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Cobrado Anualmente'
    },
    precoOitoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: '878794',
      spanBackground: '00000000',
      spanColor: '0275FF',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'ou R$ 388 à vista | ***R$ 200 de desconto***'
    },
    botaoUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 14,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: '0275FF',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 40,
        bottom: 15,
        left: 40
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_blank',
      gradienActive: false
    },
    botaoDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 14,
      color: 'FFFFFF',
      spanBackground: 'FFFFFF00',
      spanColor: 'FFFFFF',
      backgroundColor: '0275FF',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 40,
        bottom: 15,
        left: 40
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_blank',
      gradienActive: false
    },
    listaParteUmQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteUmQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/8a9772e76b421b65b16e66-bef1.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteUmQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteDoisQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDoisQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/a9772e76b421b65b16e666-bef2.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDoisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteTresQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteTresQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/9772e76b421b65b16e6663-bef3.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteTresQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/772e76b421b65b16e66633-bef4.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteQuatroQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteCincoQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteCincoQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/72e76b421b65b16e666338-bef5.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteCincoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteSeisQuadroUm: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeisQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/2e76b421b65b16e6663381-bef6.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteSeisQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Backup 24h do seu site Unic'
    },
    listaParteSeteQuadroUm: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeteQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/e76b421b65b16e66633812-bef7.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteSeteQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Acesso a todos os templates Unic'
    },
    listaParteOitoQuadroUm: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOitoQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/76b421b65b16e666338121-bef8.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteOitoQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Tutoriais e cursos'
    },
    listaParteNoveQuadroUm: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteNoveQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/6b421b65b16e6663381218-bef9.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteNoveQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Suporte 24h por Whatsapp'
    },
    listaParteDezQuadroUm: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDezQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/b421b65b16e66633812181-bef10.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDezQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Acesso às ferramentas Unic'
    },
    listaParteOnzeQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOnzeQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/421b65b16e666338121819-bef11-off.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteOnzeQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: '878794',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit',
      textDecoration: 'line-through'
    },
    listaParteDozeQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'ddddde00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDozeQuadroUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/21b65b16e666338121819a-bef12-off.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDozeQuadroUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: '878794',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit',
      textDecoration: 'line-through'
    },
    fundoQuadroDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      backgroundColor: '141416',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 20
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      },
      gradienActive: false
    },
    nomeIconeQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-gold.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    nomeTituloQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 20,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 15
      },
      text: 'Lorem Ipsum'
    },
    precoUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 800',
      fontSize: 50,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 99'
    },
    precoDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 16,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ por mês'
    },
    precoTresQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 14,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'cobrado mensalmente'
    },
    precoQuatroQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: '878794',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Assinatura mensal recorrente'
    },
    precoCincoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 800',
      fontSize: 50,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 78'
    },
    precoSeisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 16,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ mês'
    },
    precoSeteQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 14,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Cobrado Anualmente'
    },
    precoOitoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: '878794',
      spanBackground: '00000000',
      spanColor: '0275FF',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'ou R$ 784 à vista | ***R$ 400 de desconto***'
    },
    botaoUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 14,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: '0275FF',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 40,
        bottom: 15,
        left: 40
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_blank',
      gradienActive: false
    },
    botaoDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 14,
      color: 'FFFFFF',
      spanBackground: 'FFFFFF00',
      spanColor: 'FFFFFF',
      backgroundColor: '0275FF',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 40,
        bottom: 15,
        left: 40
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_blank',
      gradienActive: false
    },
    listaParteUmQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteUmQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/1b65b16e666338121819ad-bef1.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteUmQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteDoisQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDoisQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/8121819ad1fa1bf1cd0732-bef2.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDoisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteTresQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteTresQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/121819ad1fa1bf1cd0732e-bef3.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteTresQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/21819ad1fa1bf1cd0732e7-bef4.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteQuatroQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteCincoQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteCincoQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/1819ad1fa1bf1cd0732e78-bef5.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteCincoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteSeisQuadroDois: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeisQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/819ad1fa1bf1cd0732e780-bef6.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteSeisQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Backup 24h do seu site Unic'
    },
    listaParteSeteQuadroDois: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeteQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/19ad1fa1bf1cd0732e7802-bef7.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteSeteQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Acesso a todos os templates Unic'
    },
    listaParteOitoQuadroDois: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOitoQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/9ad1fa1bf1cd0732e78020-bef8.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteOitoQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Tutoriais e cursos'
    },
    listaParteNoveQuadroDois: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteNoveQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/ad1fa1bf1cd0732e780206-bef9.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteNoveQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Suporte 24h por Whatsapp'
    },
    listaParteDezQuadroDois: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDezQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/d1fa1bf1cd0732e780206a-bef10.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDezQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Acesso às ferramentas Unic'
    },
    listaParteOnzeQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOnzeQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/1fa1bf1cd0732e780206aa-bef11.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteOnzeQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteDozeQuadroDois: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDozeQuadroDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/fa1bf1cd0732e780206aa5-bef12-off.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDozeQuadroDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: '878794',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit',
      textDecoration: 'line-through'
    },
    fundoQuadroTres: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      backgroundColor: '141416',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 40,
        right: 40,
        bottom: 40,
        left: 40
      },
      gradienActive: false
    },
    nomeIconeQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-diamond.svg',
      maxWidth: 35,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    nomeTituloQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 20,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 15
      },
      text: 'Lorem Ipsum'
    },
    precoUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 800',
      fontSize: 50,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 259'
    },
    precoDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 16,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ por mês'
    },
    precoTresQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 14,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'cobrado mensalmente'
    },
    precoQuatroQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: '878794',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Assinatura mensal recorrente'
    },
    precoCincoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 800',
      fontSize: 50,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      text: 'R$ 206'
    },
    precoSeisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 16,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'right',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: '/ mês'
    },
    precoSeteQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 14,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 200,
      margin: {
        top: 5,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Cobrado Anualmente'
    },
    precoOitoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: '878794',
      spanBackground: '00000000',
      spanColor: '0275FF',
      textAlign: 'left',
      lineHeight: 100,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'ou R$ 2.052 à vista | ***R$ 1.056 de desconto***'
    },
    botaoUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 14,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      backgroundColor: '0275FF',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 40,
        bottom: 15,
        left: 40
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_blank',
      gradienActive: false
    },
    botaoDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 700',
      fontSize: 14,
      color: 'FFFFFF',
      spanBackground: 'FFFFFF00',
      spanColor: 'FFFFFF',
      backgroundColor: '0275FF',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 40,
        bottom: 15,
        left: 40
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem Ipsum',
      link: '',
      linkTarget: '_blank',
      gradienActive: false
    },
    listaParteUmQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteUmQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/b65b16e666338121819ad1-bef1.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteUmQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteDoisQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDoisQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/65b16e666338121819ad1f-bef2.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDoisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteTresQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteTresQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/5b16e666338121819ad1fa-bef3.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteTresQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/b16e666338121819ad1fa1-bef4.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteQuatroQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteCincoQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteCincoQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/16e666338121819ad1fa1b-bef5.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteCincoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteSeisQuadroTres: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeisQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/6e666338121819ad1fa1bf-bef6.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteSeisQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Backup 24h do seu site Unic'
    },
    listaParteSeteQuadroTres: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteSeteQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/e666338121819ad1fa1bf1-bef7.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteSeteQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Acesso a todos os templates Unic'
    },
    listaParteOitoQuadroTres: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOitoQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/666338121819ad1fa1bf1c-bef8.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteOitoQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Tutoriais e cursos'
    },
    listaParteNoveQuadroTres: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteNoveQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/66338121819ad1fa1bf1cd-bef9.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteNoveQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Suporte 24h por Whatsapp'
    },
    listaParteDezQuadroTres: {
      id: '',
      hidden: true,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDezQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/6338121819ad1fa1bf1cd0-bef10.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDezQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Acesso às ferramentas Unic'
    },
    listaParteOnzeQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteOnzeQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/338121819ad1fa1bf1cd07-bef11.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteOnzeQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    },
    listaParteDozeQuadroTres: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'DDDDDE00',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeParteDozeQuadroTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://upload.unicpages.com/imagens/38121819ad1fa1bf1cd073-bef12.svg',
      maxWidth: 30,
      margin: {
        top: 0,
        right: 10,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoParteDozeQuadroTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum dolor sit'
    }
  })
})
