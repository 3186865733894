<template>
  <Modal nome="secoesDesktop" class="maior">
    <div class="abas">
      <button :class="{ ativo: state.selecionado === 'padrao' }" @click="state.selecionado = 'padrao'">
        <div class="icone">
          <Svgs nome="mais" />
        </div>
        <div class="texto">
          <h3>Seções Padrão</h3>
          <p>Modelo wireframe do zero</p>
        </div>
      </button>
      <button :class="{ ativo: state.selecionado === 'prontas' }" @click="state.selecionado = 'prontas'">
        <div class="icone">
          <Svgs nome="template" />
        </div>
        <div class="texto">
          <h3>Seções Prontas</h3>
          <p>Já montadas no design</p>
        </div>
      </button>
      <button :class="{ ativo: state.selecionado === 'codigo' }" @click="state.selecionado = 'codigo'">
        <div class="icone">
          <Svgs nome="codigo" />
        </div>
        <div class="texto">
          <h3>Seções em Código</h3>
          <p>Em HTML, CSS e Javascript</p>
        </div>
      </button>
    </div>
    <SecoesDesktopCodigo v-if="state.selecionado === 'codigo'" />
    <SecoesDesktopPadrao v-if="state.selecionado === 'padrao'" />
    <SecoesDesktopProntas v-if="state.selecionado === 'prontas'" />
  </Modal>
</template>

<script setup>
import { reactive } from 'vue'
import Modal from '@components/global/modal/Modal.vue'
import SecoesDesktopCodigo from '@components/app/criar/modals/SecoesDesktopCodigo.vue'
import SecoesDesktopPadrao from '@components/app/criar/modals/SecoesDesktopPadrao.vue'
import SecoesDesktopProntas from '@components/app/criar/modals/SecoesDesktopProntas.vue'
import Svgs from '@svgs'

const state = reactive({
  selecionado: 'padrao'
})
</script>

<style scoped>
.abas {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 80px 10px 30px 0;
}

.abas button {
  display: flex;
  align-items: center;
  width: calc(33.33% - 5px);
  padding: 15px;
  border-radius: 10px;
  background-color: var(--cor-cinza-1);
  transition: all 0.3s;
}

.abas button.ativo {
  background-color: var(--cor-azul);
}

.abas button.ativo .icone {
  background-color: var(--cor-branco-fixo);
}

.abas button.ativo .icone svg {
  fill: var(--cor-azul);
}

.abas button.ativo .texto h3 {
  color: var(--cor-branco-fixo);
}

.abas button.ativo .texto p {
  color: var(--cor-branco-fixo);
}

.abas button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

.abas button .icone svg {
  width: 17px;
  min-width: 17px;
  max-height: 17px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.abas button .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 0 10px;
}

.abas button .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.abas button .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
  transition: all 0.3s;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .abas {
    width: 100%;
    padding: 60px 0 20px 0;
  }

  .abas button {
    flex-direction: column;
    width: calc(33.33% - 5px);
    padding: 25px 15px 20px 15px;
  }

  .abas button .texto {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0 0 0;
  }

  .abas button .texto h3 {
    font-size: var(--f1);
    line-height: 1.3;
    width: 100%;
    max-width: 75px;
  }

  .abas button .texto p {
    display: none;
  }
}
</style>
