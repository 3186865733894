<template>
  <div class="gradient">
    <div class="nome">
      <h3>Gradiente de Fundo</h3>
      <button :class="{ ativo: state.ativo }" @click="alternarGradiente">
        <span></span>
      </button>
    </div>

    <div class="configuracoes" v-if="state.ativo">
      <!-- Primeiro ColorPicker -->
      <div class="picker">
        <ColorPicker :valor="elementoSelecionado.gradientPrimary" :onAtualizarCor="atualizarCorUm" />
      </div>

      <!-- Segundo ColorPicker -->
      <div class="picker">
        <ColorPicker :valor="elementoSelecionado.gradientSecondary" :onAtualizarCor="atualizarCorDois" />
      </div>

      <div class="tamanho">
        <input type="tel" v-model="state.deg" class="numero" v-mask="`####`" @focus="selecionarTexto" @input="editandoDeg" />
        <p>deg</p>
        <input type="range" v-model="state.deg" min="0" max="360" class="mover" @input="editandoDeg" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, watch } from 'vue'
import { useStoreAjustes } from '@stores'
import ColorPicker from '@/components/global/elementos/ColorPicker.vue'

const props = defineProps(['selecao'])
const storeAjustes = useStoreAjustes()

const state = reactive({
  deg: 90,
  ativo: false,
  corLimpaUm: '#ffffff',
  corLimpaDois: '#000000'
})

const elementoSelecionado = computed(() => {
  const dispositivo = props.selecao?.dispositivo
  const indice = props.selecao?.indiceSecao
  const elemento = props.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
})

function alternarGradiente() {
  if (elementoSelecionado.value.gradientActive) {
    ocultarGradient()
  } else {
    ativarGradiente()
  }
}

function ocultarGradient() {
  elementoSelecionado.value.gradientPrimary = 'ffffff00'
  elementoSelecionado.value.gradientSecondary = '00000000'
  state.deg = 0
  elementoSelecionado.value.gradientActive = false
}

function ativarGradiente() {
  elementoSelecionado.value.gradientPrimary = 'ffffff'
  elementoSelecionado.value.gradientSecondary = '000000'
  state.deg = 90
  elementoSelecionado.value.gradientActive = true
}

function atualizarCorUm(novaCor) {
  elementoSelecionado.value.gradientPrimary = novaCor
}

function atualizarCorDois(novaCor) {
  elementoSelecionado.value.gradientSecondary = novaCor
}

function editandoDeg() {
  elementoSelecionado.value.gradientDeg = Number(state.deg)
}

function selecionarTexto(event) {
  setTimeout(() => {
    event.target.select()
  }, 0)
}

watch(
  () => ({
    deg: elementoSelecionado.value.gradientDeg,
    active: elementoSelecionado.value.gradientActive
  }),
  ({ deg, active }) => {
    state.deg = deg
    state.ativo = active
  },
  { immediate: true }
)
</script>

<style scoped>
.gradient {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  animation: fadeIn 0.3s ease-in-out;
  padding: 0 0 20px 0;
}

.nome {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.nome h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.nome button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 25px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
  position: relative;
  transition: all 0.3s;
}

.nome button:hover {
  background-color: var(--cor-cinza-4);
}

.nome button.ativo {
  background-color: var(--cor-azul);
}

.nome button.ativo span {
  background-color: var(--cor-branco-fixo);
  left: 25px;
}

.nome button span {
  width: 20px;
  height: 20px;
  background-color: var(--cor-cinza-5);
  border-radius: 50%;
  position: absolute;
  left: 5px;
  transition: all 0.3s;
}

.configuracoes {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px 0 20px;
}

.configuracoes .picker {
  margin: 0 0 5px 0;
}

.tamanho {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 5px 0 0 0;
}

.tamanho p {
  position: absolute;
  left: 29px;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  pointer-events: none;
}

input.numero {
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  width: 55px;
  padding: 0;
  transition: all 0.3s;
}

input.numero:focus {
  color: var(--cor-branco);
}

input.numero::-webkit-inner-spin-button,
input.numero::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input.mover {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 20px;
  background: var(--cor-cinza-3);
  border-radius: 50px;
  outline: none;
  transition: all 0.3s;
}

input.mover:hover::-webkit-slider-thumb {
  background: var(--cor-cinza-5);
}

input.mover:hover::-moz-range-thumb {
  background: var(--cor-cinza-5);
}

input.mover::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--cor-branco);
  border: 3px solid var(--cor-cinza-3);
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}

input.mover::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--cor-branco);
  border: 3px solid var(--cor-cinza-3);
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}
</style>
