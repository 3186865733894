<template>
  <div id="unicflix">
    <Nav />
    <SectionTopo />
    <SectionVideos />

    <Footer />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import Nav from './partials/Nav.vue'
import SectionTopo from './partials/SectionTopo.vue'
import SectionVideos from './partials/SectionVideos.vue'
import Footer from './partials/Footer.vue'

function alterarCoresMeta(tema) {
  const metaTags = {
    'theme-color': tema === 'claro' ? '#FFFFFF' : '#0C0C0E',
    'msapplication-TileColor': tema === 'claro' ? '#FFFFFF' : '#0C0C0E',
    'msapplication-navbutton-color': tema === 'claro' ? '#FFFFFF' : '#0C0C0E',
    'apple-mobile-web-app-status-bar-style': tema === 'claro' ? '#FFFFFF' : '#0C0C0E'
  }

  for (const [name, content] of Object.entries(metaTags)) {
    let metaTag = document.querySelector(`meta[name="${name}"]`)
    if (!metaTag) {
      metaTag = document.createElement('meta')
      metaTag.name = name
      document.head.appendChild(metaTag)
    }
    metaTag.content = content
  }
}

onMounted(() => {
  const dadosLocalStorage = JSON.parse(localStorage.getItem('personalizacao-unicpages')) || {}

  if (dadosLocalStorage.tema !== 'escuro') {
    dadosLocalStorage.tema = 'escuro'
    localStorage.setItem('personalizacao-unicpages', JSON.stringify(dadosLocalStorage))
    document.body.setAttribute('tema', 'escuro')

    alterarCoresMeta('escuro')
  }
})
</script>

<style scoped>
#unicflix {
  animation: fadeIn 0.3s linear;
}
</style>
