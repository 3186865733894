import { defineStore } from 'pinia'

export const useStoreSecao10 = defineStore('storeSecao10', {
  state: () => ({
    fundo: {
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      image: 'https://upload.unicpages.com/imagens/f1e336b755e10e910da786-bg.png',
      padding: {
        top: 150,
        right: 0,
        bottom: 150,
        left: 250
      },
      gradienActive: false
    },
    fundoTexto: {
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    divisor: {
      hidden: true,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png'
    },
    imagemPrincipal: {
      hidden: true,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 600,
      margin: {
        top: 120,
        right: 80,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    iconeTag: {
      hidden: false,
      image: 'https://upload.unicpages.com/imagens/1e336b755e10e910da786d-logo.png',
      maxWidth: 149,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoTag: {
      hidden: true,
      fontFamily: 'figtree-light',
      fontSize: 18,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    titulo: {
      hidden: false,
      fontFamily: 'Montserrat, 700',
      fontSize: 22,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: 'DEA92B',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 355,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Lorem *ipsum dolor sit amet, consectetur* adipiscing elit. Sed eget risus eget nunc.'
    },
    subtitulo: {
      hidden: false,
      fontFamily: 'Figtree, 300',
      fontSize: 14,
      color: 'BEBEBE',
      spanBackground: '00000000',
      spanColor: 'DEA92B',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 429,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget *risus eget nunc viverra tincidunt. Maecenas id tortor blandit,* pellentesque sem nec, vestibulum est. '
    },
    botao: {
      hidden: false,
      fontFamily: 'Figtree, 700',
      fontSize: 14,
      color: 'FFFFFF',
      spanBackground: 'FFFFFF00',
      spanColor: 'FFFFFF',
      backgroundColor: '09A719',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 25,
        right: 35,
        bottom: 25,
        left: 35
      },
      borderRadius: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5
      },
      text: 'LOREM IPSUM DOLOR SIT AMET',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    }
  })
})
