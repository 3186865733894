import { api } from './axios'
import { helperToken } from '@helpers'

const token = helperToken.receberToken()

export default {
  receberTodos() {
    const method = 'GET'
    const url = `templates`

    return api(method, url, null, token)
  }
}
