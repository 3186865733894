import { defineStore } from 'pinia'

export const useStoreSecao24 = defineStore('storeSecao24', {
  state: () => ({
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 80,
        right: 80,
        bottom: 80,
        left: 80
      },
      gradienActive: false
    },
    fundoQuadroUm: {
      id: '',
      hidden: false,
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: 'EFF1F7',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 10,
        right: 0,
        bottom: 0,
        left: 10
      },
      padding: {
        top: 30,
        right: 30,
        bottom: 30,
        left: 30
      },
      gradienActive: false
    },
    fundoQuadroDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      backgroundColor: 'EFF1F7',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 300,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 10,
        bottom: 10,
        left: 0
      },
      padding: {
        top: 30,
        right: 30,
        bottom: 30,
        left: 30
      },
      gradienActive: false
    },
    divisor: {
      id: '',
      hidden: true,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png'
    },
    iconeTagUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.levelmember.com/imagens/web/icone-level.svg',
      maxWidth: 40,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    iconeTagDois: {
      id: '',
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTagUm: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    textoTagDois: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloUm: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 60,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o titulo para você preencher'
    },
    tituloDois: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 60,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o titulo para você preencher'
    },
    subtituloUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 700',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Lorem ipsum sit amet dolor? Lorem dolor sit amet dolor ipsum sit.'
    },
    subtituloDois: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível usando a UnicPages no seu site'
    },
    botaoUm: {
      id: '',
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self',
      gradienActive: false
    },
    botaoDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 700',
      fontSize: 14,
      color: 'FFFFFF',
      backgroundColor: '4575FF',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'LOREM IPSUM',
      link: '#planos',
      linkTarget: '_self',
      gradienActive: false
    }
  })
})
