<template>
  <div class="maxWidth">
    <h3>Largura máxima</h3>
    <div class="tamanho">
      <input type="tel" v-model="state.valor" class="numero" v-mask="`####`" @focus="selecionarTexto" @input="editandoMaxWidth" />
      <p>px</p>
      <input type="range" v-model="state.valor" min="1" max="1920" class="mover" @input="editandoMaxWidth" />
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from 'vue'
import { useStoreAjustes } from '@stores'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()

const state = reactive({
  valor: 0
})

let timeoutId
let timeoutId2

function verificarMaximo() {
  if (state.valor > 1920) {
    state.valor = 1920
  }

  if (timeoutId2) {
    clearTimeout(timeoutId2)
  }
  timeoutId2 = setTimeout(() => {
    if (state.valor < 1) {
      elementoSelecionado().maxWidth = 1
    }
  }, 1000)

  if (timeoutId) {
    clearTimeout(timeoutId)
  }
  timeoutId = setTimeout(() => {
    if (state.valor === '') {
      elementoSelecionado().maxWidth = 1
    }
  }, 1500)
}

function carregarMaxWidth() {
  state.valor = elementoSelecionado().maxWidth
}

function editandoMaxWidth() {
  verificarMaximo()
  elementoSelecionado().maxWidth = Number(state.valor)
}

function selecionarTexto(event) {
  setTimeout(() => {
    event.target.select()
  }, 0)
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watchEffect(() => {
  carregarMaxWidth()
})
</script>

<style scoped>
.maxWidth {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
}

h3 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.tamanho {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 5px 0 0 0;
}

.tamanho p {
  position: absolute;
  left: 37px;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  pointer-events: none;
}

input.numero {
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  width: 60px;
  padding: 0;
  transition: all 0.3s;
}

input.numero:focus {
  color: var(--cor-branco);
}

input.numero::-webkit-inner-spin-button,
input.numero::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input.mover {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 20px;
  background: var(--cor-cinza-3);
  border-radius: 50px;
  outline: none;
  transition: all 0.3s;
}

input.mover:hover::-webkit-slider-thumb {
  background: var(--cor-cinza-5);
}

input.mover:hover::-moz-range-thumb {
  background: var(--cor-cinza-5);
}

input.mover::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--cor-branco);
  border: 3px solid var(--cor-cinza-3);
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}

input.mover::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--cor-branco);
  border: 3px solid var(--cor-cinza-3);
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}
</style>
