import { defineStore } from 'pinia'

export const useStoreFerramentas = defineStore('storeFerramentas', {
  state: () => ({
    mostrarModalLinkWhatsapp: false,
    mostrarModalQrCode: false,
    iconeSelecionado: false,
    icone: {
      color: 'FFFFFF',
      backgroundColor: '000000',
      maxWidth: 80,
      borderRadius: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      margin: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      gradientActive: false,
      gradientDeg: 0,
      gradientPrimary: '',
      gradientSecondary: ''
    }
  })
})
