<template>
  <section class="apresentacao">
    <div class="itens">
      <div class="linha titulo">
        <div class="item">
          <h2>Planos Unic</h2>
        </div>
        <div class="item">
          <p>Free</p>
        </div>
        <div class="item">
          <p>Silver</p>
        </div>
        <div class="item">
          <p>Gold</p>
        </div>
        <div class="item">
          <p>Diamond</p>
        </div>
        <div class="item">
          <p>Unlimited</p>
        </div>
      </div>

      <div class="linha anual">
        <div class="item">
          <h3>Assinatura recorrente anual</h3>
        </div>
        <div class="item">
          <p>R$ 0 / grátis</p>
        </div>
        <div class="item">
          <p>R$ 38 / mês</p>
        </div>
        <div class="item">
          <p>R$ 78 / mês</p>
        </div>
        <div class="item">
          <p>R$ 206 / mês</p>
        </div>
        <div class="item">
          <p>Customizado</p>
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Limite de sites</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>1 site</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>3 sites</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>10 sites</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>50 sites</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Customizado</p>
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Domínios personalizados</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>apenas unic.page</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>3 domínios</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>10 domínios</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>50 domínios</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Customizado</p>
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Visitas mensais</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>2.000</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitado</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitado</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitado</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitado</p>
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Certificado SSL</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Todos os sites</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Todos os sites</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Todos os sites</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Todos os sites</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Todos os sites</p>
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Personalização do site</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitada</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitada</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitada</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitada</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitada</p>
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>I.A para gerar textos</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>1500 fontes para usar</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>CDN para Imagens</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Banco de Ícones</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>UnicFlix</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Compartilhar site com equipe</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitado</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitado</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitado</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitado</p>
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Gerador de Link p/ Whatsapp</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Gerador de QRcode</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="categoria">
        <h2>Publicação</h2>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Otimização de imagens</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Domínio unic.page padrão</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Publicação instantânea</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Sem marca d’água</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="categoria">
        <h2>Integrações</h2>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Formulários de contato</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitados</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitados</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitados</p>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
          <p>Ilimitados</p>
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Pixel do Facebook Meta</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Google Tag Manager</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Active Campaign</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Google Maps</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Discord</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>MailChimp</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="categoria">
        <h2>Suporte</h2>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Tutoriais exclusivos</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Suporte 24h por Whatsapp</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>

      <div class="divisor"></div>

      <div class="linha padrao">
        <div class="item">
          <h3>Acesso antecipado à novidades</h3>
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-x.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
        <div class="item">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-cinza-check.svg" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>

<style scoped>
section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 20px 60px 20px;
}

.divisor {
  width: 100%;
  height: 2px;
  background-color: var(--cor-cinza-3);
  margin: 20px 0;
  padding: 0;
}

.itens {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.categoria {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.categoria h2 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 18.75%;
}

.item:nth-child(1) {
  width: 25%;
}

.linha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0 0 0;
}

.linha.titulo {
  margin: 0 0 0 0;
}

.linha.titulo .item h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.linha.titulo .item p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.linha.anual .item h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

.linha.anual .item p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

.linha.botoes {
  margin: 10px 0 20px 0;
}

.linha.botoes .item a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  text-decoration: none;
  padding: 11px 17px;
  border-radius: 10px;
  transition: all 0.3s;
}

.linha.botoes .item a.off {
  pointer-events: none;
  background-color: var(--cor-cinza-3);
  color: var(--cor-cinza-5);
}

.linha.botoes .item a:hover {
  background-color: #004faf;
}

.linha.botoes .item a img {
  width: 10px;
  min-width: 10px;
  max-height: 10px;
  margin: 0 5px 0 0;
}

.linha.botoes .item a.cinza {
  background-color: #202022;
}

.linha.botoes .item a.cinza:hover {
  background-color: #2e2e31;
}

.linha.padrao .item {
  align-items: center;
}

.linha.padrao .item img {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  margin: 0 10px 0 0;
}

.linha.padrao .item p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.linha.padrao .item h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}
@media screen and (max-width: 1024px) {
  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0 20px 50px 20px;
    overflow: scroll;
    scrollbar-width: none;
  }

  section::-webkit-scrollbar {
    display: none;
  }

  .itens {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 1150px;
  }

  .divisor {
    width: 100%;
    height: 2px;
    margin: 15px 0;
    padding: 0;
  }

  .categoria {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 19.5%;
  }

  .item:nth-child(1) {
    width: 22%;
  }

  .linha {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin: 5px 0 0 0;
  }

  .linha.titulo {
    margin: 0 0 5px 0;
  }

  .linha.botoes {
    margin: 10px 0 20px 0;
  }

  .linha.botoes .item a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 10px;
    transition: all 0.3s;
  }

  .linha.botoes .item a img {
    width: 12px;
    min-width: 12px;
    max-height: 12px;
    margin: 0 5px 0 0;
  }

  .linha.padrao .item {
    align-items: center;
  }

  .linha.padrao .item img {
    width: 11px;
    min-width: 11px;
    max-height: 11px;
    margin: 0 8px 0 0;
  }
}
</style>
