<template>
  <section class="aviso">
    <div class="conteudo">
      <!-- Unika -->
      <div class="banner" @click="storeModal.abrirModal('linkBioIa', 'link', 'Criar link de bio com Unika')">
        <div class="foto">
          <Svgs nome="ia-flat" />
          <div class="fundo"></div>
        </div>
        <div class="texto">
          <h3>Link de bio com I.A</h3>
        </div>
        <Particles class="flat" colorTheme="default" />
      </div>
      <!-- UnicFlix -->
      <div class="banner unicflix" @click="router.push('/unicflix')">
        <div class="foto">
          <Svgs nome="play" />
          <div class="fundo"></div>
        </div>
        <div class="texto">
          <h3>Cursos na UnicFlix</h3>
        </div>
      </div>
      <!-- Ícones -->
      <div class="banner icones" @click="router.push('/icones')">
        <div class="foto">
          <Svgs nome="icones" />
          <div class="fundo"></div>
        </div>
        <div class="texto">
          <h3>Banco de Ícones</h3>
        </div>
      </div>
      <!-- Level Member -->
      <div class="banner level" @click="irParaLevel()">
        <div class="foto">
          <Svgs nome="level" />
          <div class="fundo"></div>
        </div>
        <div class="texto">
          <h3>Construtor de comunidades</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'
import Particles from '@components/global/particles/Particles.vue'

const router = useRouter()
const storeModal = useStoreModal()

function irParaLevel() {
  window.open('https://levelmember.com', '_blank')
}
</script>

<style scoped>
section.aviso {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 0 20px;
}
.conteudo {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(25% - 10px);
  background-color: var(--cor-cinza-2);
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
}
.banner:hover {
  background-color: var(--cor-cinza-3);
}
.banner .foto {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  min-width: 45px;
  height: 45px;
  min-height: 45px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0 15px 0 0;
  z-index: 3;
}
.banner .foto svg {
  width: 17px;
  min-width: 17px;
  fill: var(--cor-branco-fixo);
  animation: zoom 20s ease-in-out infinite;
  z-index: 2;
}
.banner .foto .fundo {
  position: absolute;
  width: 70px;
  height: 70px;
  background: linear-gradient(20deg, var(--degrade));
  animation: girando 10s linear infinite;
  border-radius: 50%;
}
.banner.level .foto .fundo {
  background: linear-gradient(20deg, #bc39ff, #f84291, #ff2121, #ff631a, #ffd000, #ff9720);
}
.banner.unicflix .foto .fundo {
  background: linear-gradient(20deg, #b31217, #e50914, #ff1e1e, #ff4545, #ff6b6b, #ff9191);
}
.banner.icones .foto .fundo {
  background: linear-gradient(20deg, #12b35d, #12b38d, #0aa27f, #12b35d, #12b38d, #12b35d);
}
.banner .texto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 3;
}
.banner .texto h3 svg {
  width: 10px;
  min-width: 10px;
  margin: 0 5px;
}
.banner .texto h3 {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}
@media screen and (max-width: 1000px) {
  section.aviso {
    display: flex;
    width: 100%;
    padding: 20px 20px 0 20px;
  }
  .conteudo::-webkit-scrollbar {
    display: none;
  }
  .conteudo {
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .banner {
    display: flex;
    align-items: center;
    padding: 15px;
    min-width: 240px;
    width: 240px;
    margin: 0 15px 0 0;
  }
  .banner .foto {
    width: 50px;
    min-width: 50px;
    height: 50px;
    margin: 0 10px 0 0;
    border-radius: 10px;
  }
  .banner .foto svg {
    width: 20px;
    min-width: 20px;
  }
  .banner .foto .fundo {
    width: 80px;
    height: 80px;
  }
  .banner button {
    font-size: var(--f0);
    padding: 11px 18px;
    margin: 8px 0 0 0;
  }
  .banner button:hover {
    filter: brightness(120%);
  }
  .banner .texto {
    flex-direction: column;
    align-items: flex-start;
  }
  .banner .texto h3 {
    font-size: var(--f2);
    line-height: 1.5;
  }
}
</style>
