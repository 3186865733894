import { defineStore } from 'pinia'

export const useStoreSecao7 = defineStore('storeSecao7', {
  state: () => ({
    fundo: {
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '000001',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 100,
        right: 80,
        bottom: 100,
        left: 80
      },
      gradienActive: false
    },
    fundoTexto: {
      hidden: true,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    divisor: {
      hidden: true,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png'
    },
    iconeTag: {
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTag: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    titulo: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 60,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o titulo para você preencher'
    },
    subtitulo: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },
    botao: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self'
    },
    fundoForm: {
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'ffffff00',
      maxWidth: 500,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      gradienActive: false
    },
    iconeTagForm: {
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    textoTagForm: {
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 300,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloForm: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Alexandria, 900',
      fontSize: 41,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '00D535',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 400,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'LOREM *IPSUM SIT AMET DOLOR* IPSUM BRA SIT'
    },
    subtituloForm: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 300',
      fontSize: 16,
      color: 'FFFFFF94',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 15,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis, eros vel gravida elementum, nisl sapien dignissim erat, sed condimentum erat arcu at lectus.'
    },
    botaoForm: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter, 600',
      fontSize: 16,
      color: 'FFFFFF',
      spanBackground: 'FFFFFF00',
      spanColor: 'FFFFFF',
      backgroundColor: '00D535',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 40,
        bottom: 20,
        left: 40
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem Ipsum',
      link: 'https://discord.com/api/webhooks/1259671003227750430/MYgxXOzvsRjtt1QvVz8SpNC1jdb3oo8Bubl4rRA2xOFVvJbj3G92fXS4BE3mAxK5I4rC',
      linkTarget: '_self',
      gradienActive: false
    },
    labelUm: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 600',
      fontSize: 14,
      color: 'FFFFFF',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Lorem Ipsum'
    },
    inputUm: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree, 300',
      fontSize: 18,
      color: 'FFFFFF',
      backgroundColor: '1F1F1F',
      textAlign: 'left',
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: 'Lorem ipsum...'
    },
    labelDois: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: ''
    },
    inputDois: {
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui'
    },
    labelTres: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: ''
    },
    inputTres: {
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui'
    },
    modal: {
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '000001ff',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 100,
        right: 80,
        bottom: 100,
        left: 80
      },
      gradienActive: false
    },
    fundoTextoModal: {
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    iconeTagModal: {
      hidden: true,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      }
    },
    textoTagModal: {
      hidden: true,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },
    tituloModal: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Dela Gothic One',
      fontSize: 40,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 100,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'RECEBI SEU FEEDBACK'
    },
    subtituloModal: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter',
      fontSize: 16,
      color: 'ffffffff',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 429,
      margin: {
        top: 20,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'Agora vamos ao conteúdo! Chame o Edu no whatsapp para receber seu acesso ao closefriends.'
    },
    botaoModal: {
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Inter',
      fontSize: 16,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '00D535',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 20,
        right: 40,
        bottom: 20,
        left: 40
      },
      borderRadius: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      text: '**Chamar Edu**',
      link: 'https://wa.me/551831993340',
      linkTarget: '_blank',
      gradienActive: false
    }
  })
})
