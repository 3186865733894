<template>
  <section class="lista">
    <div class="quadro">
      <div class="conteudo">
        <div class="tela">
          <div class="icone">
            <Svgs nome="template" />
          </div>
          <p>Templates Unic</p>
        </div>

        <div class="plano">
          <img :src="`https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-${storePerfil.usuario?.assinatura?.tipo || 'default'}.svg`" />
          <div class="info">
            <div class="progresso">
              <div class="atual" :style="{ width: `${percentualProgresso || 100}%` }"></div>
            </div>
            <p>
              {{ storePages.pages?.length }} de {{ limitePlano }} {{ textoLimitePlano }}
              <span>{{ percentualProgressoTexto }}%</span>
            </p>
          </div>
        </div>

        <div class="input">
          <Svgs class="icone" nome="lupa" />
          <input type="text" spellcheck="false" placeholder="Buscar template" v-model="termoBusca" @input="filtrarTemplates" />
        </div>
      </div>
    </div>

    <div class="categorias" v-for="(templates, categoria) in templatesPorCategoria" :key="categoria">
      <h2>{{ categoriasMapeadas[categoria] || categoria }}</h2>
      <button class="seta voltar" @click="moverSlide($event, 'voltar')">
        <Svgs nome="setinha" />
      </button>
      <button class="seta avancar" @click="moverSlide($event, 'avancar')">
        <Svgs nome="setinha" />
      </button>
      <div class="itens" ref="sliders">
        <div class="item" v-for="template in templates" :key="template._id" @click="storeModal.abrirModalEdicao('verTemplate', 'template', 'Visualizar Template', template)">
          <div class="conteudo">
            <div class="info">
              <h3>{{ template.nome }}</h3>
              <p>/{{ template.hotlink }}</p>
            </div>
            <div class="capa">
              <div class="tamanho">
                <div class="imagem" :style="`background-image: url('${template.imagemCapa || ''}')`"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, onMounted, watchEffect, ref, inject, computed } from 'vue'
import { useStoreTemplates, useStoreModal, useStorePerfil, useStorePages } from '@stores'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
const storeModal = useStoreModal()
const storeTemplates = useStoreTemplates()
const storePages = useStorePages()
const termoBusca = ref('')
const templatesPorCategoria = ref({})
const emitter = inject('emitter')
const sliders = reactive({})

const limitePlano = computed(() => {
  const tipoPlano = storePerfil.usuario?.assinatura?.tipo
  switch (tipoPlano) {
    case 'free':
      return 1
    case 'silver':
      return 3
    case 'gold':
      return 10
    case 'diamond':
      return 50
    case 'unlimited':
      return 1000
    default:
      return 0
  }
})

const categoriasMapeadas = {
  'academia': 'Academia',
  'academia-de-luta': 'Academia de Luta',
  'acai': 'Açaí',
  'acougue': 'Açougue',
  'assistencia-tecnica': 'Assistência Técnica',
  'cabeleireiro': 'Cabeleireiro',
  'consultorio-medico': 'Consultório Médico',
  'contabilidade': 'Contabilidade',
  'conveniencia': 'Conveniência',
  'dentista': 'Dentista',
  'escola-de-idiomas': 'Escola de Idiomas',
  'escola-e-faculdade': 'Escola e Faculdade',
  'escritorio-advocacia': 'Escritório Advocacia',
  'estetica-automotiva': 'Estética Automotiva',
  'farmacia': 'Farmácia',
  'hamburgueria': 'Hamburgueria',
  'imobiliaria': 'Imobiliária',
  'link-de-bio': 'Link de Bio',
  'loja-de-moveis': 'Loja de Móveis',
  'loja-de-roupa': 'Loja de Roupa',
  'loja-de-tintas': 'Loja de Tintas',
  'maquiadora': 'Maquiadora',
  'marketing-digital': 'Marketing Digital',
  'materiais-de-construcao': 'Materiais de Construção',
  'mercearia': 'Mercearia',
  'mecanica': 'Mecânica',
  'padaria': 'Padaria',
  'papelaria': 'Papelaria',
  'pet-shop': 'Pet Shop',
  'portfolio': 'Portfólio',
  'sorveteria': 'Sorveteria'
}

function moverSlide(event, direcao) {
  const container = event.target.closest('.categorias').querySelector('.itens')
  if (!container) return

  const distancia = 335
  container.scrollLeft += direcao === 'avancar' ? distancia : -distancia
}

const formatarQuantidade = (quantidade, singular, plural) => {
  return quantidade === 1 ? singular : plural
}

const textoLimitePlano = computed(() => {
  const pages = storePages.pages || []
  const quantidade = pages.filter((page) => page).length
  return `${formatarQuantidade(quantidade, 'site', 'sites')}`
})

const percentualProgressoTexto = computed(() => {
  const quantidadeCriada = storePages.pages?.length || 0
  const limite = limitePlano.value
  return limite > 0 ? Math.round((quantidadeCriada / limite) * 100) : 0
})

const percentualProgresso = computed(() => {
  const quantidadeCriada = storePages.pages?.length || 0
  const limite = limitePlano.value
  const progresso = limite > 0 ? (quantidadeCriada / limite) * 100 : 0
  return Math.min(Math.round(progresso), 100)
})

function carregarTemplates() {
  storeTemplates.receberTodos()
  filtrarTemplates()
}

function filtrarTemplates() {
  const termo = termoBusca.value.trim().toLowerCase()
  const agrupadosPorCategoria = {}

  const templatesFiltrados =
    termo === ''
      ? storeTemplates.templates
      : storeTemplates.templates.filter((template) => {
          return template.nome?.toLowerCase().includes(termo) || template.hotlink.toLowerCase().includes(termo) || template.tags?.some((tag) => tag.toLowerCase().includes(termo))
        })

  templatesFiltrados.forEach((template) => {
    if (!agrupadosPorCategoria[template.categoria]) {
      agrupadosPorCategoria[template.categoria] = []
    }
    agrupadosPorCategoria[template.categoria].push(template)
  })

  templatesPorCategoria.value = agrupadosPorCategoria
}

watchEffect(() => {
  filtrarTemplates()
})

onMounted(() => {
  carregarTemplates()
  emitter.on('carregarTemplates', carregarTemplates())
})
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0 0 0;
  min-height: 100dvh;
}

.quadro {
  display: flex;
  width: 100%;
  padding: 0 20px 20px 20px;
}

.quadro .conteudo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  border-radius: 10px;
}

.quadro .tela {
  display: flex;
  align-items: center;
  margin: 0 30px 0 0;
}

.quadro .tela .icone {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
}

.quadro .tela .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}
.quadro .tela p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro .plano {
  display: flex;
  align-items: center;
}

.quadro .plano img {
  width: 100%;
  max-width: 30px;
  max-height: 30px;
}

.quadro .plano .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  margin: 0 0 0 10px;
}

.quadro .plano .info .progresso {
  width: 100%;
  height: 3px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
}

.quadro .plano .info .progresso .atual {
  width: 100%;
  height: 3px;
  border-radius: 50px;
  background-color: var(--cor-branco);
  transition: all 3s;
}

.quadro .plano .info p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  width: 100%;
  margin: 10px 0 0 0;
}

.quadro .plano .info p span {
  color: var(--cor-cinza-5);
}

.quadro .input {
  max-width: 500px;
  margin-left: auto;
}

.quadro .input input {
  background-color: var(--cor-cinza-3);
}

.categorias {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 30px 0;
  position: relative;
}

.categorias h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 0 20px;
  z-index: 4;
}

button.seta {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  z-index: 3;
}

button.seta.avancar:hover svg {
  transform: rotate(-90deg) scale(1.5);
}

button.seta.voltar:hover svg {
  transform: rotate(90deg) scale(1.5);
}

button.seta svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

button.seta.avancar {
  right: 0;
  bottom: 0;
  background: linear-gradient(-90deg, #0c0c0e 20%, #0c0c0e00);
  padding: 15px 15px 0 30px;
}

button.seta.avancar svg {
  transform: rotate(-90deg);
}

button.seta.voltar {
  left: 0;
  bottom: 0;
  background: linear-gradient(90deg, #0c0c0e 20%, #0c0c0e00);
  padding: 15px 30px 0 15px;
}

button.seta.voltar svg {
  transform: rotate(90deg);
}

.itens {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-x: scroll;
  padding: 20px 20px 0 20px;
  scrollbar-width: none;
  scroll-behavior: smooth;
  transition: all 0.3s;
}

.itens::-webkit-scrollbar {
  display: none;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-2);
  border-radius: 10px;
  padding: 8px;
  margin: 0 15px 0 0;
  cursor: pointer;
  transition: all 0.3s;
}

.item:hover {
  border: 1px solid var(--cor-cinza-4);
}

.item .conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.item .info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 20px 10px;
}

.item .info h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.item .info p {
  font-family: var(--light);
  font-size: var(--f0);
  color: var(--cor-cinza-5);
}

.item .capa {
  width: 100%;
}

.item .capa .tamanho {
  padding-bottom: 75%;
  position: relative;
  width: 100%;
}

.item .capa .tamanho .imagem {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px var(--cor-cinza-1);
}
/* Responsivo */
@media screen and (max-width: 1000px) {
  section.lista {
    display: flex;
    flex-direction: column;
  }

  button.seta {
    display: none;
  }

  .quadro .conteudo {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
  }

  .quadro .tela {
    width: 50%;
    margin: 0;
  }

  .quadro .tela .icone {
    width: 30px;
    height: 30px;
    margin: 0 8px 0 0;
  }

  .quadro .tela .icone svg {
    width: 15px;
    min-width: 15px;
  }
  .quadro .tela p {
    font-size: var(--f2);
  }

  .quadro .plano {
    justify-content: flex-end;
    width: 50%;
  }

  .quadro .plano img {
    max-width: 15px;
    max-height: 15px;
  }

  .quadro .plano .info {
    flex-direction: row-reverse;
    align-items: center;
    margin: 0;
    width: auto;
  }

  .quadro .plano .info .progresso {
    display: none;
    width: 80px;
    min-width: 80px;
  }

  .quadro .plano .info p {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0 0 0 5px;
  }

  .quadro .plano .info p span {
    margin: 0 0 0 5px;
  }

  .quadro .input {
    max-width: 100%;
    margin: 15px 0 0 0;
  }

  .item {
    width: 100%;
  }

  .item .botoes button {
    width: 60px;
  }
}
</style>
