<template>
  <div id="planos">
    <SectionAtual />
    <SectionPlanos />
    <SectionContato />
    <SectionComparar />
    <SectionDuvida />
    <Footer />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStorePerfil } from '@stores'
import SectionPlanos from '@components/app/planos/partials/SectionPlanos.vue'
import SectionComparar from '@components/app/planos/partials/SectionComparar.vue'
import Footer from '@components/global/footer/Footer.vue'
import SectionContato from '@components/app/planos/partials/SectionContato.vue'
import SectionDuvida from '@components/app/planos/partials/SectionDuvida.vue'
import SectionAtual from '@components/app/planos/partials/SectionAtual.vue'

const storePerfil = useStorePerfil()

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

onMounted(() => {
  carregarPerfil()
})
</script>

<style scoped>
#planos {
  padding: 60px 0 0 0;
  animation: fadeIn 0.3s linear;
}
</style>
