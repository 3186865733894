<template>
  <Modal nome="excluirSecaoMobile">
    <div class="inputAtivacao" :class="{ ativo: state.confirmar }" @click="state.confirmar = !state.confirmar">
      <span>
        <Svgs nome="check" />
      </span>
      <p>Sim, desejo excluir essa seção</p>
    </div>

    <BotaoExcluir nome="Excluir seção" @click="excluirSecao" />
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted, computed, watch } from 'vue'
import { useStoreAjustes, useStoreModal, useStoreCriar } from '@stores'
import Svgs from '@svgs'
import Modal from '@components/global/modal/Modal.vue'
import BotaoExcluir from '@components/global/elementos/BotaoExcluir.vue'

const emitter = inject('emitter')
const storeCriar = useStoreCriar()
const storeAjustes = useStoreAjustes()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('excluirSecaoMobile'))

const state = reactive({
  confirmar: false,
  id: ''
})

function excluirSecao() {
  if (state.confirmar === false) {
    const mensagem = 'Marque a caixinha para confirmar'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
    return
  }

  storeAjustes.removerSecao(state.id, false)
  storeCriar.removerSecao(state.id, false)
  emitter.emit('atualizarCamadasSite')
  emitter.emit('renderizarComponentesTela')
  emitter.emit('ocultarAjustes')

  state.id = ''
  storeModal.fecharModal('excluirSecaoMobile')
}

async function pegarIdSecao() {
  if (dadosModal.value.id) {
    state.id = dadosModal.value.id
  }
}

function limparCampos() {
  state.confirmar = false
  state.id = ''
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarIdSecao()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
